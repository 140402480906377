import {ContainerNull,Container,ContainerLojas,Titulo,AreaHeader,AreaPesquisa,ButtonNovo,ContainerTabela,Tabela,Thead,TR,TH,Tbody,TD,SpanStatus,ButtonEditar} from './estilo';
import { useState,useContext,useEffect, useRef, useCallback } from 'react';
import api from '../../../services/api';
import { AuthContext } from '../../../contexts/auth';
import { toast } from 'react-toastify';
import AlteraLoja from '../../modals/Editar_loja';
import InputPesquisa from '../../InputPesquisa';
import InserirLoja from '../../modals/Novo_loja';
import {IoAlertCircleOutline} from 'react-icons/io5';
import RelatorioLink from '../../RelatorioLink';

export default function Lojasdash(){
    const [modal,setModal] = useState(false);
    const [modalNovo,setModalNovo] = useState(false);
    const [dadosLoja, setDadosLoja] = useState('');
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);
    const [lojas,setLojas] = useState([]);
    const [listaDeLojas, setListaDeLojas] = useState([]);
    const lojasFields = ['id','nome','centroCusto','varejo','dataAbertura','liderSenior','telefoneS','liderDistrital','telefoneD','cnpj','endereco','bairro','cep','cidade','estado','status','createdAt','updatedAt']
    const componentMounted = useRef(true); 

    //busca todas as lojas
    const buscaLojas = useCallback(() => {
        setLoading(true)
        api.get('/lojas',
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setLojas(resp.data.lojas)
            setListaDeLojas(resp.data.lojas)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    },[user.token, signOut]);

    useEffect(()=>{
        if (componentMounted.current){ 
            buscaLojas()
        }
        return () => {
            componentMounted.current = false;
        }
    },[buscaLojas])

    function enviaDados(item){
        setModal(!modal)
        setDadosLoja(item)
    }

    function abreNovo(){
        setModalNovo(!modalNovo);
    }

    function filtroDeLojas(lista, valor){
        return lista.filter((loja) => loja.nome.toUpperCase().includes(valor.toUpperCase()) || loja.centroCusto.includes(valor));
    }

    if(loading){
        return(
            <Container>
                <ContainerLojas>
                    <Titulo>Carregando...</Titulo>
                </ContainerLojas>
            </Container>
        )
    }
    return(
        <Container>
            <ContainerLojas>
                <Titulo>LISTA DE LOJAS</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={lojas} setLista={setListaDeLojas} funcaoDeFiltro={filtroDeLojas}/>
                    </AreaPesquisa>
                    <ButtonNovo onClick={abreNovo}>
                        Novo
                    </ButtonNovo>
                    <RelatorioLink dados={listaDeLojas} fields={lojasFields} filename={"lojas"}/>
                </AreaHeader>
                {listaDeLojas.length === 0 ? (
                        <ContainerNull>
                            <IoAlertCircleOutline size={50} color='#FF0000'/>
                            <h1>Nenhum item encontrado...</h1>
                        </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela>
                                <Thead>
                                    <TR>
                                        <TH scope='col'>Id</TH>
                                        <TH scope='col'>Loja</TH>
                                        <TH scope='col'>Centro de Custo</TH>
                                        <TH scope='col'>Varejo</TH>
                                        <TH scope='col'>Data de Abertura</TH>
                                        <TH scope='col'>Status</TH>
                                        <TH scope='col'>fornecedor</TH>
                                        <TH scope='col'>#</TH>
                                    </TR>
                                </Thead>
                                    <Tbody>
                                            {listaDeLojas.map((loja)=>{
                                                    return(
                                                        
                                                            <TR key={loja.id}>
                                                                <TD data-label='id'>{loja.id}</TD>
                                                                <TD data-label='loja'>{loja.nome}</TD>
                                                                <TD data-label='centroCusto'>{loja.centroCusto}</TD>
                                                                <TD data-label='varejo'>{loja.varejo}</TD>
                                                                <TD data-label='dataAbertura'>{loja.dataAbertura === '' ? 'Sem cadastro' : loja.dataAbertura}</TD>
                                                                <TD data-label='status'>
                                                                    <SpanStatus style={{backgroundColor:loja.status === 'ATIVO' ? '#29C013' : '#FF0000' }}>{loja.status}</SpanStatus>
                                                                </TD>
                                                                <TD data-label='fornecedor'>{loja.fornecedor}</TD>
                                                                <TD data-label='#'>
                                                                    <ButtonEditar onClick={()=> enviaDados(loja)}>
                                                                        EDITAR
                                                                    </ButtonEditar>
                                                                </TD>
                                                            </TR>
                                                    
                                                        
                                                    )
                                                }
                                            )
                                            }
                                    </Tbody>
                            </Tabela>
                        </ContainerTabela>
                    )
                }
                {modal && (
                    <AlteraLoja
                    conteudo={dadosLoja}
                    fechar={enviaDados}
                    atualizaLojas={buscaLojas}
                    />
                    )
                }
                {
                modalNovo && (
                    <InserirLoja
                    fechar={abreNovo}
                    atualizaLojas={buscaLojas}
                    />
                )
                }
            </ContainerLojas>
        </Container>
    )
}