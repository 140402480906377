import { useState,useEffect,useContext, useRef, useCallback} from 'react';
import api from '../../../services/api'; 
import { AuthContext } from '../../../contexts/auth';
import { toast } from 'react-toastify';
import {ContainerNull,Container,ContainerUsuarios,Titulo,AreaHeader,AreaPesquisa,ButtonNovo,ContainerTabela,Tabela,Thead,TR,TH,Tbody,TD,SpanStatus,ButtonEditar} from './estilo';
import AlteraUsuario from '../../modals/Editar_usuario';
import InputPesquisa from '../../InputPesquisa';
import NovoUsuario from '../../modals/Novo_Usuario';
import {IoAlertCircleOutline} from 'react-icons/io5';
import RelatorioLink from '../../RelatorioLink';

export default function Usuariosdash(){
    const [modal,setModal] = useState(false);
    const [modalNovo,setModalNovo] = useState(false);
    const [dadosUser,setDadosUser] = useState('')
    const [usuarios,setUsuarios] = useState('');
    const [listaDeUsuarios, setListaDeUsuarios] = useState([]);
    const [loading,setLoading] = useState(false);
    const {user,signOut} = useContext(AuthContext);
    const usuariosFields = ['id','usuario','nome','email','cpf','dataNasc','status','perfilId'];
    const componentMounted = useRef(true); 
    
    //busca todos os usuarios
    const buscaUsuarios = useCallback(() => {
        setLoading(true)
        api.get('/usuarios',
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setUsuarios(resp.data.usuarios)
            setListaDeUsuarios(resp.data.usuarios)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    },[user.token, signOut]);
    
    useEffect(()=>{
        if (componentMounted.current){ 
            buscaUsuarios()
        }
        return () => {
            componentMounted.current = false;
        }
    },[buscaUsuarios])

    function enviaDados(item){
        setModal(!modal)
        setDadosUser(item)
    }

    function abreNovo(){
        setModalNovo(!modalNovo)
    }

    function filtroDeUsuarios(lista, valor){
        return lista.filter((usuario) => usuario.nome.toUpperCase().includes(valor.toUpperCase()) || usuario.usuario.includes(valor));
    }

    if(loading){
        return(
            <Container>
                <ContainerUsuarios>
                    <Titulo>Carregando...</Titulo>
                </ContainerUsuarios>
            </Container>
        )
    }
    return(
        <Container>
            <ContainerUsuarios>
                <Titulo>LISTA DE USUARIOS</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={usuarios} setLista={setListaDeUsuarios} funcaoDeFiltro={filtroDeUsuarios}/>
                    </AreaPesquisa>
                    <ButtonNovo onClick={abreNovo}>
                        Novo
                    </ButtonNovo>
                    <RelatorioLink dados={listaDeUsuarios} fields={usuariosFields} filename={"usuarios"}/>
                </AreaHeader>
                {listaDeUsuarios.length === 0 ? (
                        <ContainerNull>
                            <IoAlertCircleOutline size={50} color='#FF0000'/>
                            <h1>Nenhum item encontrado...</h1>
                        </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela>
                                <Thead>
                                    <TR>
                                        <TH scope='col'>Id</TH>
                                        <TH scope='col'>Usuario</TH>
                                        <TH scope='col'>Nome</TH>
                                        <TH scope='col'>Email</TH>
                                        <TH scope='col'>Status</TH>
                                        <TH scope='col'>Perfil</TH>
                                        <TH scope='col'>#</TH>
                                    </TR>
                                </Thead>
                                    <Tbody>
                                            {listaDeUsuarios.map((usuario)=>{
                                                    return(
                                                            <TR key={usuario.id}>
                                                                <TD data-label='id'>{usuario.id}</TD>
                                                                <TD data-label='usuario'>{usuario.usuario}</TD>
                                                                <TD data-label='nome'>{usuario.nome}</TD>
                                                                <TD data-label='email'>{usuario.email === '' ? 'Sem cadastro' : usuario.email}</TD>
                                                                <TD data-label='status'>
                                                                    <SpanStatus style={{backgroundColor:usuario.status === 'ATIVO' ? '#29C013' : '#FF0000' }}>{usuario.status}</SpanStatus>
                                                                </TD>
                                                                <TD data-label='permissao'>{usuario.permissao}</TD>
                                                                <TD data-label='#'>
                                                                    <ButtonEditar onClick={()=> enviaDados(usuario)}>
                                                                        EDITAR
                                                                    </ButtonEditar>
                                                                </TD>
                                                            </TR>
                                                    
                                                        
                                                    )
                                                }
                                            )
                                            }
                                    </Tbody>
                            </Tabela>
                        </ContainerTabela>
                    )
                }
                {modal && (
                    <AlteraUsuario
                    conteudo={dadosUser}
                    fechar={enviaDados}
                    atualizaUsuarios={buscaUsuarios}
                    />
                    )
                }
                {modalNovo && (
                    <NovoUsuario
                    fechar={abreNovo}
                    atualizaUsuarios={buscaUsuarios}
                    />
                )}
            </ContainerUsuarios>
        </Container>
    )
}