import styled from 'styled-components';

export const StyledTr = styled.tr`

    .error-td > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align:center;
        padding-left: 10px;
    }

    .error-td:hover{
        position: relative;
    }

    .error-td:hover > p {
        overflow: initial;
        text-overflow: initial;
        background-color: white;
        border: 1px solid black;
        position: absolute;
        display: flex;
        top:0;
        left: 50%;
        transform: translate(-50%, 0);
        align-items: center;
        padding: 10px;
        height: 35px;
    }
`