import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {cpf} from 'cpf-cnpj-validator';
import { createContext } from 'react';

export const AdicionarUsuarioContext = createContext({});

export default function AdicionarUsuarioProvider({children}){

    function cpfMask(value){
        return value
          .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    yup.addMethod(yup.string, "cpfValidate", function (errorMessage) {
        return this.test(`cpf-validate`, errorMessage, function (value) {
            const cpfNumber = value.replace('.', '').replace('.', '').replace('-', '')
            if(cpf.isValid(cpfNumber)){
                return value
            }
            const { path, createError } = this;
        
            return createError({ path, message: errorMessage })
        });
    });

    const schema = yup.object().shape({
        usuario: yup.string().required('Preencha o campo usuario.'),
        senha: yup.string().min(4,'Minimo de 4 caracteres'),
        nome: yup.string().required('Preencha o campo nome.'),
        perfil: yup.number().min(1,'Selecione uma opção.'),
        status: yup.string().required('Selecione uma opção'),
    })

    const { register, handleSubmit, reset , formState: {errors} } = useForm({
        resolver: yupResolver(schema)
    })

    return(
        <AdicionarUsuarioContext.Provider value={{cpfMask,register,handleSubmit,reset,errors}}>
            {children}
        </AdicionarUsuarioContext.Provider>
    )
}
