import styled from 'styled-components';

export const ContainerNull = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    height: 250px;
    border: 1px solid black;
    background-color: #FFF;
    margin-bottom: 10px;
    padding: 8px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #EFEFEC;
    height: 100%;
`;

export const ContainerCategorias = styled.div`
    margin-left: 230px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 100%;
    border-radius: 9px;
    background-color: #FFF;
    @media(max-width:700px){
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 100%;
        border-radius: 9px;
        background-color: #FFF;
    }
`;

export const Titulo = styled.h1`
    background-color: #DC4405;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    color: #FFF;
    padding: 10px;
`;

export const AreaHeader = styled.div`
    display: flex;
    flex-direction: row;
`;

export const AreaPesquisa = styled.div`
    display: flex;
    width: 250px;
    margin: 15px;
    padding: 2px;
    border-radius: 9px;
    border:solid #EFEFEC 2px;
    background-color: #FFF;
    @media screen and (max-width: 700px){
        margin-right: 0;
    }
`;

export const ButtonNovo = styled.button`
    width: 50px;
    height: 30px;
    margin: 15px;
    color: #F28F1A;
    font-weight: bold;
    border: solid #EFEFEC 1px;
    background-color:#FFF;
    border-radius: 9px;
    &:hover{
        background-color: #DC4405;
        color: #FFF;
    }
    @media screen and (max-width: 700px){
        padding: 10px;
        margin-right: 0;
        margin-left: 10px;
        width: 60px;
        height: 40px;
    }
`;

//Tabela de itens

export const ContainerTabela = styled.div`
    margin: 15px;
    display: flex;
    justify-content: center;
`;

export const Tabela = styled.table`
&caption{
    font-size: 1.5em;
    margin: .5em 0 .75em;
}
`;

export const Thead = styled.thead`
    display: table;
    width: 100%;
    table-layout: fixed;
    @media(max-width:700px){
        border: none;
        clip:rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
`;

export const TR = styled.tr`
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 20px;
    text-align: center;
    @media(max-width:700px){
        border-bottom: 3px solid #DDD;
        display: block;
        margin-bottom: .65em;
    }
`;

export const TH = styled.th`
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
    background-color: #DC4405;
    color: #FFF;
    padding:10px;
    text-align: center;
`;

export const Tbody = styled.tbody`
    display: block;
    width: 100%;
`;

export const TD = styled.td`
    @media(max-width:700px){
        border-bottom: 1px solid #DDD;
        display: block;
        font-size: .8em;
        text-align: right;
        &::before{
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
`;

export const SpanStatus = styled.span`
    padding: 3px;
    border-radius: 3px;
    color: #FFF;
`;

export const ButtonEditar = styled.button`
    border: 0;
    padding: 5px;
    align-items: center;
    background-color: #00A8FF;
    color: #FFF;
    font-weight: bold;
    border-radius: 3px ;
    &:hover{
        background-color: #FFF;
        color: #00A8FF;
    }
`;
