import img from '../../img/swift-laranja-preto.svg';
import {useContext } from 'react';
import {AuthContext} from '../../contexts/auth';
import { IoPersonCircleSharp } from 'react-icons/io5';
import { HeaderContainer } from './estilo';

export default function Header(){
    const {signOut,user} = useContext(AuthContext);

    return(
        <HeaderContainer>
            <div className='area-img'>
                <img src={img} alt="logoswift"/>
            </div>
            <div className='area-components'>
                <h3>Olá, {user.nome}</h3>
                <h3>ID:{user.id_usuario}</h3>
                <IoPersonCircleSharp size={50} color='#000' className='logoPerfil'/>
                <button onClick={()=> signOut()}>Sair</button>
            </div>
        </HeaderContainer>
    )
}