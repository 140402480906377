import HeaderLateral from "../../components/HeaderLateral";
import Usuariosdash from "../../components/Dash/Usuariosdash";
import './usuarios.css';
import {Helmet} from 'react-helmet';

export default function Usuarios(){
    return(
        <div className="usercontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Usuarios</title>
            </Helmet>
            <HeaderLateral/>
            <Usuariosdash/>
        </div>
    )
}