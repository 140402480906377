import {IoSearchOutline} from 'react-icons/io5'; 
import {InputPesquisaContainer, InputPesquisar} from "./estilo";

export default function InputPesquisa({lista, setLista, funcaoDeFiltro}){
    function atualizarLista(valor){
        const listaFiltrada = funcaoDeFiltro(lista, valor);
        setLista(listaFiltrada);
    }

    return (
        <InputPesquisaContainer>
            <IoSearchOutline size={20}/>
            <InputPesquisar placeholder='Pesquisar' onChange={(e) => atualizarLista(e.target.value)} />
        </InputPesquisaContainer>
    )
}