import { useState,useEffect,useContext } from 'react';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,TituloArea,AreaItem,SubTitulo,Select,Input,Button,AreaButton} from './estilo.js';
import {AuthContext} from '../../../contexts/auth';
import { toast } from 'react-toastify';
import api from '../../../services/api.js';
import ErrosTable from '../../ErrorsTable/index.js';
import ErrorsTr from '../../ErrorsTr/index.js';
import { IoChevronBackOutline } from "react-icons/io5";
import UploadXlsx from '../../UploadXlsx/index.js';
import criarCategoriasXlsx from "../../../planilhasDeExemplo/criar_categorias.xlsx";

export default function InserirCategoria({fechar,atualizaCategoria}){
    const [categoria,setCategoria] = useState('')
    const [status, setStatus] = useState('')
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);
    const [errorsMsg,setErrorsMsg] = useState('');

    //EDITA CATEGORIA
    function AdicionaCategoria(){
        setLoading(true)
        if(categoria !== '' && status !== ''){
            api.post('/categorias/cadastro',
            {
                nome:categoria.toUpperCase(),
                status:status
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            toast.success(resp.data.response)
            window.location.reload()
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
        }else{
            toast.info('Existem campos vazio')
        }
    }

    function criarCategorias(results){
        const categorias = results.data;
        if(user.token){
            api.post('/categorias/cadastros',
            {
                categorias
            }, 
            {
                headers:{
                    'Authorization': user.token
                }
            }).then((resp) => {
                toast.success(resp.data.mensagem)
                window.location.reload()
            }).catch((error) => {
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                } else if (error.response.status === 406) {
                    toast.info(error.response.data.error)
                } else if (error.response.status === 413){
                    toast.info('Quantidade de dados muito grande.');
                } else {
                    toast.info("Erro ao criar categorias.");
                    setErrorsMsg(error.response.data.errors);
                }
            })
        }
    } 

    return(
        <Modal>
            {errorsMsg ? (
                 <ContainerModal>
                    <h1 className='errors-title'>Lista de erros</h1>
                    <div className='errors-table-container'>
                        <ErrosTable titleColumns={['mensagem','item','valor']}>
                            {errorsMsg.map((erro) => {
                                return <ErrorsTr erro={erro}/>
                            })}
                        </ErrosTable>
                    </div>
                    <button className='errors-button' onClick={(e) => setErrorsMsg('')}>
                        <IoChevronBackOutline size={20}/>
                        VOLTAR
                    </button>
                </ContainerModal>
            ) : (
                <ContainerModal>
                    <Titulo>INSERIR CATEGORIA</Titulo>
                    <div className='upload-container'>
                        <UploadXlsx resultCallback={criarCategorias} planilhaDeExemplo={criarCategoriasXlsx}/>
                    </div>
                    <ContainerConteudo>
                        <TituloArea>DADOS CATEGORIA</TituloArea>
                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>CATEGORIA*</SubTitulo>
                                <Input id='categoria' type='text' value={categoria || ''} onChange={(e)=> setCategoria(e.target.value)}></Input>
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>STATUS*</SubTitulo>
                                <Select value={status} onChange={(e)=>{setStatus(e.target.value)}}>
                                    <option key={0} value=''>Selecione</option>
                                    <option key={1} value='ATIVO'>ATIVO</option>
                                    <option key={2} value='DESATIVADO'>DESATIVADO</option>
                                </Select>
                            </AreaItem>
                        </ContainerItem>
                        <AreaButton>
                                <Button onClick={fechar}>VOLTAR</Button>
                                <Button onClick={AdicionaCategoria}>SALVAR</Button>
                        </AreaButton>
                    </ContainerConteudo>
                </ContainerModal>
            )}
        </Modal>
    )
}