import { useState,useContext,useEffect } from 'react';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,TituloArea,AreaItem,SubTitulo,Select,Input,Button,AreaButton} from './estilo.js';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import {AuthContext} from '../../../contexts/auth';

export default function AlteraFornecedor({conteudo,fechar,atualizaFornecedores}){
    const [fornecedor, setFornecedor] = useState(conteudo.nome)
    const [email,setEmail] = useState(conteudo.email)
    const [cep,setCep] = useState(conteudo.cep)
    const [endereco, setEndereco] = useState(conteudo.endereco)
    const [cidade, setCidade] = useState(conteudo.cidade)
    const [estado, setEstado] = useState(conteudo.estado)
    const [status, setStatus] = useState(conteudo.status)
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);

    //EDITA FORNECEDOR
    function editaFornecedor(){
        api.patch(`/fornecedores/${conteudo.id}`,
            {
                nome:fornecedor,
                email:email,
                cep:cep,
                endereco:endereco,
                cidade:cidade,
                estado:estado,
                status:status
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            toast.success(resp.data.mensagem)
            fechar()
            atualizaFornecedores()
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }
    return(
        <Modal>
            <ContainerModal>
                <Titulo>EDITAR FORNECEDOR</Titulo>
                <ContainerConteudo>
                    <TituloArea>DADOS DO FORNECEDOR</TituloArea>
                    <ContainerItem>
                        <AreaItem>
                            <SubTitulo>FORNECEDOR</SubTitulo>
                            <Input type='text' value={fornecedor || ''} onChange={(e)=>setFornecedor(e.target.value)}></Input>
                        </AreaItem>
                        <AreaItem>
                            <SubTitulo>EMAIL</SubTitulo>
                            <Input type="email" value={email || ''} onChange={(e)=> setEmail(e.target.value)}></Input>
                        </AreaItem>
                    </ContainerItem>

                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>CEP</SubTitulo>
                                <Input type='text' value={cep || ''} onChange={(e)=> setCep(e.target.value)}></Input>
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>ENDEREÇO</SubTitulo>
                                <Input type='text' value={endereco || ''} onChange={(e)=> setEndereco(e.target.value)}></Input>
                            </AreaItem>
                        </ContainerItem>

                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>CIDADE</SubTitulo>
                                <Input type='text' value={cidade || ''} onChange={(e)=>setCidade(e.target.value)}></Input>
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>ESTADO</SubTitulo>
                                <Input type='text' value={estado || ''} onChange={(e)=>setEstado(e.target.value)}></Input>
                            </AreaItem>
                        </ContainerItem>

                    <ContainerItem>
                        <AreaItem>
                            <SubTitulo>STATUS</SubTitulo>
                            <Select value={status} onChange={(e)=>{setStatus(e.target.value)}}>
                                <option key={1} value='ATIVO'>ATIVO</option>
                                <option key={2} value='DESATIVADO'>DESATIVADO</option>
                            </Select>
                        </AreaItem>
                    </ContainerItem>
                            <AreaButton>
                                    <Button onClick={fechar}>VOLTAR</Button>
                                    <Button onClick={editaFornecedor}>SALVAR</Button>
                            </AreaButton>
                </ContainerConteudo>
            </ContainerModal>
        </Modal>
    )
}