import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 150px;
    padding: 5px;
    .area-img{
        display: flex;
        align-items: center;
        width: 30%;
        height: 80%;
        margin-left: 25px;
        margin-top: 15px;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        background-color: #FFF;
    }

    .area-img img{
        height: 80%;
        width: 30%;
        margin-left: 20px;
        margin-right: 20px;
    }

    .area-components{
        display: flex;
        flex-direction: row;
        width: 70%;
        height: 80%;
        margin-top: 15px;
        margin-right: 25px;
        justify-content:flex-end;
        align-items: center;
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
        background-color: #FFF;
    }
    .logoPerfil{
        margin-left: 30px;
        margin-right: 15px;
        border-radius: 30px;
    }

    .area-components h3{
        font-size: 15px;
        margin-left: 15px;
    }

    .area-components button{
        margin-right: 25px;
        border: 0;
        background-color: #DC4405;
        padding: 5px;
        border-radius: 9px;
        color: #FFF;
        font-weight: bold;
        font-size: 15px;
        transition: all .4s;
    }

    .area-components button:hover{
        background-color: #FFF;
        color: #F28F1A;
    }

    @media screen and (max-width: 800px) {
        .area-img{
            width: 100px;
        }
        .area-img img{
            width: 100%;
            margin: 0;
        }
    }
`