import { useState,useContext,useEffect, useRef } from 'react';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,ContainerPreco,TituloArea,AreaItem,SubTitulo,Select,Input,Button,AreaButton} from './estilo.js';
import {AuthContext} from '../../../contexts/auth';
import { AdicionarProdutoContext } from '../../../contexts/adicionarProduto';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ErrosTable from '../../ErrorsTable/index.js';
import ErrorsTr from '../../ErrorsTr/index.js';
import { IoChevronBackOutline } from "react-icons/io5";
import UploadXlsx from '../../UploadXlsx/index.js';
import criarProdutosXlsx from "../../../planilhasDeExemplo/criar_produtos.xlsx";

export default function InserirProduto({fechar,atualizaProdutos}){
    const [codigo, setCodigo] = useState('')
    const [produto,setProduto] = useState('')
    const [descricao,setDescricao] = useState('')
    const [descricao2,setDescricao2] = useState('')
    const [peso,setPeso] = useState('')
    const [categorias,setCategorias] = useState([])
    const [categoriaSelecionada,setCategoriaSelecionada] = useState(0)
    const [tipoLoja_id,setTipoLoja_id] = useState(0)
    const [precoB,setPrecoB] = useState(0)
    const [precoAC,setPrecoAC] = useState(0)
    const [precoAL,setPrecoAL] = useState(0)
    const [precoAP,setPrecoAP] = useState(0)
    const [precoAM,setPrecoAM] = useState(0)
    const [precoBA,setPrecoBA] = useState(0)
    const [precoCE,setPrecoCE] = useState(0)
    const [precoDF,setPrecoDF] = useState(0)
    const [precoES,setPrecoES] = useState(0)
    const [precoGO,setPrecoGO] = useState(0)
    const [precoMA,setPrecoMA] = useState(0)
    const [precoMT,setPrecoMT] = useState(0)
    const [precoMS,setPrecoMS] = useState(0)
    const [precoMG,setPrecoMG] = useState(0)
    const [precoPA,setPrecoPA] = useState(0)
    const [precoPB,setPrecoPB] = useState(0)
    const [precoPR,setPrecoPR] = useState(0)
    const [precoPE,setPrecoPE] = useState(0)
    const [precoPI,setPrecoPI] = useState(0)
    const [precoRN,setPrecoRN] = useState(0)
    const [precoRS,setPrecoRS] = useState(0)
    const [precoRO,setPrecoRO] = useState(0)
    const [precoRR,setPrecoRR] = useState(0)
    const [precoSC,setPrecoSC] = useState(0)
    const [precoSP,setPrecoSP] = useState(0)
    const [precoSE,setPrecoSE] = useState(0)
    const [precoTO,setPrecoTO] = useState(0)
    const [precoRJ,setPrecoRJ] = useState(0)
    const [preco_sp_guanabara,setPreco_sp_guanabara] = useState(0)
    const [precoAnapolis_fisica,setPrecoAnapolis_fisica] = useState(0)
    const [precoSP_fisica,setPrecoSP_fisica] = useState(0)
    const [precoDF_fisica,setPrecoDF_fisica] = useState(0)
    const [precoGO_fisica,setPrecoGO_fisica] = useState(0)
    const [precoRJ_fisica,setPrecoRJ_fisica] = useState(0)
    const [status, setStatus] = useState('')
    const [errorsMsg,setErrorsMsg] = useState('');

    const {user,signOut} = useContext(AuthContext);
    const {register,handleSubmit,errors} = useContext(AdicionarProdutoContext);
    const [loading,setLoading] = useState(false);
    const componentMounted = useRef(true); 

    useEffect(()=>{

        //BUSCA FORNECEDOR
        if (componentMounted.current){ 
            buscaCategorias()
        }
        return () => {
            componentMounted.current = false;
        }
    },[user.token,signOut])

    function buscaCategorias(){
        setLoading(true)
        api.get('/categorias',
        {
            headers:{
                'Authorization':user.token
            }
        })
        .then((resp)=>{
            setCategorias(resp.data.categorias)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }

    //NOVO PRODUTOS
    function adicionaProduto(data){
        api.post('/produtos/cadastro',
            {
                codigo:data.codigo,
                produto:data.produto,
                descricao:data.descricao,
                descricao2:data.descricao2,
                peso:data.peso,
                categoriaId:data.categoriaId,
                tipoLoja_id:data.tipoLoja_id,
                status:data.status,
                precoB:precoB,
                precoAC:precoAC,
                precoAL:precoAL,
                precoAP:precoAP,
                precoAM:precoAM,
                precoBA:precoBA,
                precoCE:precoCE,
                precoDF:precoDF,
                precoES:precoES,
                precoGO:precoGO,
                precoMA:precoMA,
                precoMT:precoMT,
                precoMS:precoMS,
                precoMG:precoMG,
                precoPA:precoPA,
                precoPB:precoPB,
                precoPR:precoPR,
                precoPE:precoPE,
                precoPI:precoPI,
                precoRN:precoRN,
                precoRS:precoRS,
                precoRO:precoRO,
                precoRR:precoRR,
                precoSC:precoSC,
                precoSP:precoSP,
                precoSE:precoSE,
                precoTO:precoTO,
                precoRJ:precoRJ,
                preco_sp_guanabara:preco_sp_guanabara,
                preco_sp_fisica:precoSP_fisica,
                preco_df_fisica:precoDF_fisica,
                preco_go_fisica:precoGO_fisica,
                preco_rj_fisica:precoRJ_fisica,
                preco_anapolis_fisica:precoAnapolis_fisica,
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            toast.success(resp.data.mensagem)
            window.location.reload()
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }
    
    function criarProdutos(results){
        const produtos = results.data;
        if(user.token){
            api.post('/produtos/cadastros', {produtos}, {
                headers:{
                    'Authorization': user.token
                }
            }).then((resp) => {
                toast.success(resp.data.mensagem)
                window.location.reload()
            }).catch((error) => {
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                } else if (error.response.status === 406) {
                    toast.info(error.response.data.error)
                } else if (error.response.status === 413){
                    toast.info('Quantidade de dados muito grande.');
                } else {
                    toast.info("Erro ao criar produtos.");
                    setErrorsMsg(error.response.data.errors);
                }
            })
        }
    } 
    
    return(
        <Modal>
            {errorsMsg ? (
                <ContainerModal>
                    <h1 className='errors-title'>Lista de erros</h1>
                    <div className='errors-table-container'>
                        <ErrosTable titleColumns={['mensagem','item','valor']}>
                            {errorsMsg.map((erro) => {
                                return <ErrorsTr erro={erro}/>
                            })}
                        </ErrosTable>
                    </div>
                    <button className='errors-button' onClick={(e) => setErrorsMsg('')}>
                        <IoChevronBackOutline size={20}/>
                        VOLTAR
                    </button>
                </ContainerModal>
            ) : (
                <ContainerModal>
                    <Titulo>NOVO PRODUTO</Titulo>
                    <div className='upload-container'>
                        <UploadXlsx resultCallback={criarProdutos} planilhaDeExemplo={criarProdutosXlsx}/>
                    </div>
                    <ContainerConteudo onSubmit={handleSubmit(adicionaProduto)}>
                        <TituloArea>DADOS PRODUTO</TituloArea>
                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>CODIGO</SubTitulo>
                                <Input type='number' value={codigo} {...register('codigo',{onChange:(e)=>setCodigo(e.target.value)})}/>
                                {errors.codigo && <p style={{color:'#FF0000'}}>{errors.codigo.message}</p>}
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>PRODUTO</SubTitulo>
                                <Input type='text' value={produto} {...register('produto',{onChange:(e)=>setProduto(e.target.value)})}/>
                                {errors.codigo && <p style={{color:'#FF0000'}}>{errors.codigo.message}</p>}
                            </AreaItem>
                            <AreaItem>
                                    <SubTitulo>SEGMENTO DE LOJA</SubTitulo>
                                    <Select value={tipoLoja_id} {...register('tipoLoja_id',{onChange:(e)=>setTipoLoja_id(e.target.value)})}>
                                        <option key={0} value={0}>SELECIONAR</option>
                                        <option key={1} value={1}>1 - SOMENTE VAREJO</option>
                                        <option key={2} value={2}>2 - SOMENTE LOJA FISICA</option>
                                        <option key={3} value={3}>3 - VAREJO & FISICA</option>
                                    </Select>
                                    {errors.tipoLoja_id && <p style={{color:'#FF0000'}}>{errors.tipoLoja_id.message}</p>}
                                </AreaItem>
                        </ContainerItem>
                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>DESCRICAO</SubTitulo>
                                    <Input type='text' value={descricao} {...register('descricao',{onChange:(e)=>setDescricao(e.target.value)})}/>
                                    {errors.descricao && <p style={{color:'#FF0000'}}>{errors.descricao.message}</p>}
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>DESCRICAO 2</SubTitulo>
                                    <Input type='text' value={descricao2} {...register('descricao2',{onChange:(e)=>setDescricao2(e.target.value)})}/>
                                    {errors.descricao2 && <p style={{color:'#FF0000'}}>{errors.descricao2.message}</p>}
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PESO</SubTitulo>
                                    <Input type='text' value={peso} {...register('peso',{onChange:(e)=>setPeso(e.target.value)})}/>
                                    {errors.peso && <p style={{color:'#FF0000'}}>{errors.peso.message}</p>}
                                </AreaItem>
                            </ContainerItem>
                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>CATEGORIA</SubTitulo>
                                    <Select value={categoriaSelecionada} {...register('categoriaId',{onChange:(e)=>setCategoriaSelecionada(e.target.value)})}>
                                        <option key={0} value={0}>SELECIONAR</option>
                                        {categorias.map((categoria)=>{
                                            return <option key={categoria.id} value={categoria.id}>{categoria.id} - {categoria.categoria}</option>
                                        })
                                        }
                                    </Select>
                                    {errors.categoriaId && <p style={{color:'#FF0000'}}>{errors.categoriaId.message}</p>}
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>STATUS</SubTitulo>
                                    <Select value={status} {...register('status',{onChange:(e)=>{setStatus(e.target.value)}})}>
                                        <option key={0} value=''>SELECIONAR</option>
                                        <option key={1} value='ATIVO'>ATIVO</option>
                                        <option key={2} value='DESATIVADO'>DESATIVADO</option>
                                    </Select>
                                    {errors.status && <p style={{color:'#FF0000'}}>{errors.status.message}</p>}
                                </AreaItem>
                            </ContainerItem>
                            <TituloArea>PREÇOS DE VAREJO</TituloArea>
                            <ContainerPreco>
                                <AreaItem>
                                    <SubTitulo>PRECO BARBOSA</SubTitulo>
                                    <Input type='number' value={precoB} onChange={(e)=>setPrecoB(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO AC</SubTitulo>
                                    <Input type='number' value={precoAC} onChange={(e)=>setPrecoAC(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO AL</SubTitulo>
                                    <Input type='number' value={precoAL} onChange={(e)=>setPrecoAL(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO AP</SubTitulo>
                                    <Input type='number' value={precoAP} onChange={(e)=>setPrecoAP(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO AM</SubTitulo>
                                    <Input type='number' value={precoAM} onChange={(e)=>setPrecoAM(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO BA</SubTitulo>
                                    <Input type='number' value={precoBA} onChange={(e)=>setPrecoBA(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO CE</SubTitulo>
                                    <Input type='number' value={precoCE} onChange={(e)=>setPrecoCE(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO DF</SubTitulo>
                                    <Input type='number' value={precoDF} onChange={(e)=>setPrecoDF(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO ES</SubTitulo>
                                    <Input type='number' value={precoES} onChange={(e)=>setPrecoES(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO GO</SubTitulo>
                                    <Input type='number' value={precoGO} onChange={(e)=>setPrecoGO(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO MA</SubTitulo>
                                    <Input type='number' value={precoMA} onChange={(e)=>setPrecoMA(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO MT</SubTitulo>
                                    <Input type='number' value={precoMT} onChange={(e)=>setPrecoMT(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO MS</SubTitulo>
                                    <Input type='number' value={precoMS} onChange={(e)=>setPrecoMS(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO MG</SubTitulo>
                                    <Input type='number' value={precoMG} onChange={(e)=>setPrecoMG(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PA</SubTitulo>
                                    <Input type='number' value={precoPA} onChange={(e)=>setPrecoPA(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PB</SubTitulo>
                                    <Input type='number' value={precoPB} onChange={(e)=>setPrecoPB(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PR</SubTitulo>
                                    <Input type='number' value={precoPR} onChange={(e)=>setPrecoPR(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PE</SubTitulo>
                                    <Input type='number' value={precoPE} onChange={(e)=>setPrecoPE(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO PI</SubTitulo>
                                    <Input type='number' value={precoPI} onChange={(e)=>setPrecoPI(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RN</SubTitulo>
                                    <Input type='number' value={precoRN} onChange={(e)=>setPrecoRN(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RS</SubTitulo>
                                    <Input type='number' value={precoRS} onChange={(e)=>setPrecoRS(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RO</SubTitulo>
                                    <Input type='number' value={precoRO} onChange={(e)=>setPrecoRO(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RR</SubTitulo>
                                    <Input type='number' value={precoRR} onChange={(e)=>setPrecoRR(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO SC</SubTitulo>
                                    <Input type='number' value={precoSC} onChange={(e)=>setPrecoSC(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO SP</SubTitulo>
                                    <Input type='number' value={precoSP} onChange={(e)=>setPrecoSP(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO SE</SubTitulo>
                                    <Input type='number' value={precoSE} onChange={(e)=>setPrecoSE(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO TO</SubTitulo>
                                    <Input type='number' value={precoTO} onChange={(e)=>setPrecoTO(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO RJ</SubTitulo>
                                    <Input type='number' value={precoRJ} onChange={(e)=>setPrecoRJ(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO GUANABARA</SubTitulo>
                                    <Input type='number' value={preco_sp_guanabara} onChange={(e)=>setPreco_sp_guanabara(e.target.value)}/>
                                </AreaItem>
                            </ContainerPreco>
                            <TituloArea>PREÇOS DE LOJA FISICA</TituloArea>
                            <ContainerPreco>
                                <AreaItem>
                                    <SubTitulo>PRECO_SP_FISICA</SubTitulo>
                                    <Input type='number' value={precoSP_fisica} onChange={(e)=>setPrecoSP_fisica(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_DF_FISICA</SubTitulo>
                                    <Input type='number' value={precoDF_fisica} onChange={(e)=>setPrecoDF_fisica(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_GO_FISICA</SubTitulo>
                                    <Input type='number' value={precoGO_fisica} onChange={(e)=>setPrecoGO_fisica(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_RJ_FISICA</SubTitulo>
                                    <Input type='number' value={precoRJ_fisica} onChange={(e)=>setPrecoRJ_fisica(e.target.value)}/>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>PRECO_ANAPOLIS_FISICA</SubTitulo>
                                    <Input type='number' value={precoAnapolis_fisica} onChange={(e)=>setPrecoAnapolis_fisica(e.target.value)}/>
                                </AreaItem>
                            </ContainerPreco>
                                <AreaButton>
                                        <Button onClick={fechar}>VOLTAR</Button>
                                        <Button type='submit'>ADICIONAR</Button>
                                </AreaButton>
                    </ContainerConteudo>
                </ContainerModal>
            )}
        </Modal>
    )
}