import HeaderLateral from "../../components/HeaderLateral";
import {Helmet} from 'react-helmet';
import RelatorioPrecosExecutados from "../../components/Dash/RelatorioPrecosExecutados";

export default function PrecosExecutados(){
    return(
        <div className="forncontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Preços Executados</title>
            </Helmet>
            <HeaderLateral/>
            <RelatorioPrecosExecutados/>
        </div>
    )
}