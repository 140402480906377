import styled from 'styled-components';

export const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #DC4405;

    .area-loja{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        margin-left: 30px;
        margin-right: 30px;
        background-color: #FFF;
        min-width: 50%;
        height: 80%;
    }

    .area-loja h1{
        margin-bottom: 10px;
    }
`