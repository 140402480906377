import { useState,useContext,useEffect, useRef } from 'react';
import api from '../../../services/api';
import { AuthContext } from '../../../contexts/auth';
import { toast } from 'react-toastify';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,AreaItem,SubTitulo,Select,Input,Button,AreaButton, AreaItemSelect, ContainerItemSelect} from './estilo.js';
import { AdicionarUsuarioContext } from '../../../contexts/adicionarUsuario';

export default function AlteraUsuario({conteudo,fechar,atualizaUsuarios}){
    const {user,signOut} = useContext(AuthContext);
    const {cpfMask} = useContext(AdicionarUsuarioContext)
    const [usuario,setUsuario] = useState(conteudo.usuario)
    const [senha,setSenha] = useState('');
    const [nome, setNome] = useState(conteudo.nome)
    const [cpf, setCpf] = useState(conteudo.cpf)
    const [email, setEmail] = useState(conteudo.email)
    const [dataNasc,setDataNasc] = useState(conteudo.dataNasc)
    const [perfils, setPerfils] = useState([])
    const [perfilSelecionado,setPerfilSelecionado] = useState(conteudo.perfilId)
    const [statusSelecionado, setStatusSelecionado] = useState(conteudo.status)
    const [loading,setLoading] = useState(false)
    const componentMounted = useRef(true); 

    useEffect(()=>{
        //BUSCA PERFILS
        if (componentMounted.current){ 
            buscaPerfils()
        }
        return () => {
            componentMounted.current = false;
        }
    },[user.token,signOut])

    function buscaPerfils(){
        setLoading(true)
        api.get('/perfil',
        {
            headers:{
                'Authorization':user.token
            }
        })
        .then((resp)=>{
            setPerfils(resp.data.perfils)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }
    
    //EDITA USUARIO
    function editaUsuario(){
        api.patch('/usuarios',
            {
                usuario_id:conteudo.id,
                usuario:usuario,
                senha:senha,
                nome:nome,
                email:email,
                documento:cpf,
                data_nascimento:dataNasc,
                perfil_id:perfilSelecionado,
                status:statusSelecionado,
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            toast.success(resp.data.mensagem)
            fechar()
            atualizaUsuarios()
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }

    return(
        <Modal onClick={fechar}>
            <ContainerModal onClick={(e) => e.stopPropagation()}>
                <Titulo>EDITAR USUÁRIO</Titulo>
                <ContainerConteudo>
                    <ContainerItem>
                        <AreaItem>
                            <SubTitulo>USUARIO</SubTitulo>
                            <Input type='number' value={usuario || ''} onChange={(e)=>setUsuario(e.target.value)}></Input>
                        </AreaItem>
                        <AreaItem>
                            <SubTitulo>SENHA</SubTitulo>
                            <Input type='password' placeholder='****' value={senha || ''} onChange={(e)=> setSenha(e.target.value)}></Input>
                        </AreaItem>
                    </ContainerItem>
                    <ContainerItem>
                        <AreaItem>
                            <SubTitulo>NOME</SubTitulo>
                            <Input type='text' value={nome || ''} onChange={(e)=> setNome(e.target.value)}></Input>
                        </AreaItem>
                        <AreaItem>
                            <SubTitulo>EMAIL</SubTitulo>
                            <Input type='email' value={email || ''} onChange={(e)=>setEmail(e.target.value)}></Input>
                        </AreaItem>
                    </ContainerItem>
                    <ContainerItem>
                        <AreaItem>
                            <SubTitulo>CPF</SubTitulo>
                            <Input type='text' value={cpf || ''} onChange={(e)=>setCpf(cpfMask(e.target.value))}></Input>
                        </AreaItem>
                        <AreaItem>
                            <SubTitulo>DATA DE NASCIMENTO</SubTitulo>
                            <Input type='date' value={dataNasc || ''} onChange={(e)=>setDataNasc(e.target.value)}></Input>
                        </AreaItem>
                    </ContainerItem>
                    <ContainerItemSelect>
                        <AreaItemSelect>
                            <SubTitulo>PERFIL</SubTitulo>
                            <Select value={perfilSelecionado || ''} onChange={(e)=>{setPerfilSelecionado(e.target.value)}}>
                                {perfils.map((perfil)=>{
                                    return <option key={perfil.id} value={perfil.id}>{perfil.nome}</option>
                                })
                                }
                            </Select>
                        </AreaItemSelect>
                        <AreaItemSelect>
                            <SubTitulo>STATUS</SubTitulo>
                            <Select value={statusSelecionado} onChange={(e)=>{setStatusSelecionado(e.target.value)}}>
                                <option key={1} value='ATIVO'>ATIVO</option>
                                <option key={2} value='DESATIVADO'>DESATIVADO</option>
                            </Select>
                        </AreaItemSelect>
                    </ContainerItemSelect>
                    <AreaButton>
                            <Button onClick={fechar}>VOLTAR</Button>
                            <Button onClick={editaUsuario}>SALVAR</Button>
                    </AreaButton>
                </ContainerConteudo>
            </ContainerModal>
        </Modal>
    )
}