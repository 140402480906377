import { useState,useContext,useEffect } from 'react';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,AreaItem,SubTitulo,Select,Input,Button,AreaButton} from './estilo.js';
import { AuthContext } from '../../../contexts/auth.js';
import { toast } from 'react-toastify';
import api from '../../../services/api';


export default function AlteraLoja({conteudo,fechar,atualizaLojas}){
    const [nome, setNome] = useState(conteudo.nome)
    const [centroCusto,setCentroCusto] = useState(conteudo.centroCusto)
    const [varejo, setVarejo] = useState(conteudo.varejo)
    const [dataAbertura,setDataAbertura] = useState(conteudo.dataAbertura)
    const [liderSenior, setLiderSenior] = useState(conteudo.liderSenior)
    const [telefoneS, setTelefoneS] = useState(conteudo.telefoneS)
    const [liderDistrital, setLiderDistrital] = useState(conteudo.liderDistrital)
    const [telefoneD, setTelefoneD] = useState(conteudo.telefoneD)
    const [cnpj,setCnpj] = useState(conteudo.cnpj)
    const [endereco, setEndereco] = useState(conteudo.endereco)
    const [bairro, setBairro] = useState(conteudo.bairro)
    const [cep,setCep] = useState(conteudo.cep)
    const [cidade, setCidade] = useState(conteudo.cidade)
    const [estado, setEstado] = useState(conteudo.estado)
    const [fornecedorId,setFornecedorId] = useState(conteudo.fornecedorId)
    const [fornecedores, setFornecedores] = useState([])
    const [precos,setPrecos] = useState([])
    const [precoSelecionado,setPrecoSelecionado] = useState(conteudo.preco_id)
    const [statusSelecionado, setStatusSelecionado] = useState(conteudo.status)

    const [loading,setLoading] = useState(false);
    const {user,signOut} = useContext(AuthContext);

    useEffect(()=>{
        setLoading(true)
        //BUSCA FORNECEDOR
        function buscaFornecedores(){
            api.get('/fornecedores',
            {
                headers:{
                    'Authorization':user.token
                }
            })
            .then((resp)=>{
                setFornecedores(resp.data.fornecedores)
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }
        function buscaPreco(){
            api.get('/precos_produto',
            {
                headers:{
                    'Authorization':user.token
                }
            })
            .then((resp)=>{
                setPrecos(resp.data.precos)
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }
        buscaFornecedores()
        buscaPreco()
        setLoading(false)
    },[user.token,signOut])

    //EDITA LOJA
    function editaLoja(){
        setLoading(true)
        api.patch('/lojas',
            {
                nome:nome,
                centroCusto:centroCusto,
                varejo:varejo,
                dataAbertura:dataAbertura,
                liderSenior:liderSenior,
                telefoneS:telefoneS,
                liderDistrital:liderDistrital,
                telefoneD:telefoneD,
                cnpj:cnpj,
                endereco:endereco,
                bairro:bairro,
                cep:cep,
                cidade:cidade,
                estado:estado,
                fornecedor_id:fornecedorId,
                precos_produto_id:precoSelecionado,
                status:statusSelecionado,
                loja_id:conteudo.id
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            toast.success(resp.data.mensagem)
            window.location.reload()
            fechar()
            atualizaLojas()
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }
    return(
        <Modal>
            <ContainerModal>
                <Titulo>EDITAR LOJA</Titulo>
                <ContainerConteudo>
                    <ContainerItem>
                        <AreaItem>
                            <SubTitulo>LOJA</SubTitulo>
                            <Input type='text' value={nome || ''} onChange={(e)=>setNome(e.target.value)}></Input>
                        </AreaItem>
                        <AreaItem>
                            <SubTitulo>Centro Custo</SubTitulo>
                            <Input type='number' value={centroCusto || ''} onChange={(e)=> setCentroCusto(e.target.value)}></Input>
                        </AreaItem>
                    </ContainerItem>

                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>VAREJO</SubTitulo>
                                <Input type='text' value={varejo || ''} onChange={(e)=> setVarejo(e.target.value)}></Input>
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>CNPJ</SubTitulo>
                                <Input type='text' value={cnpj || ''} onChange={(e)=> setCnpj(e.target.value)}></Input>
                            </AreaItem>
                        </ContainerItem>

                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>LIDER SENIOR</SubTitulo>
                                <Input type='text' value={liderSenior || ''} onChange={(e)=>setLiderSenior(e.target.value)}></Input>
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>TELEFONE SENIOR</SubTitulo>
                                <Input type='tel' value={telefoneS || ''} onChange={(e)=>setTelefoneS(e.target.value)}></Input>
                            </AreaItem>
                        </ContainerItem>

                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>LIDER DISTRITAL</SubTitulo>
                                <Input type='text' value={liderDistrital || ''} onChange={(e)=>setLiderDistrital(e.target.value)}></Input>
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>TELEFONE DISTRITAL</SubTitulo>
                                <Input type='tel' value={telefoneD || ''} onChange={(e)=>setTelefoneD(e.target.value)}></Input>
                            </AreaItem>
                        </ContainerItem>

                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>DATA DE ABERTURA</SubTitulo>
                                    <Input type='date' value={dataAbertura || ''} onChange={(e)=>setDataAbertura(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>CEP</SubTitulo>
                                    <Input type='text' value={cep || ''} onChange={(e)=>setCep(e.target.value)}></Input>
                                </AreaItem>
                            </ContainerItem>

                                <ContainerItem>
                                    <AreaItem>
                                        <SubTitulo>ENDEREÇO</SubTitulo>
                                        <Input type='text' value={endereco || ''} onChange={(e)=>setEndereco(e.target.value)}></Input>
                                    </AreaItem>
                                    <AreaItem>
                                        <SubTitulo>BAIRRO</SubTitulo>
                                        <Input type='text' value={bairro || ''} onChange={(e)=>setBairro(e.target.value)}></Input>
                                    </AreaItem>
                                </ContainerItem>
                                    <ContainerItem>
                                        <AreaItem>
                                            <SubTitulo>CIDADE</SubTitulo>
                                            <Input type='text' value={cidade || ''} onChange={(e)=>setCidade(e.target.value)}></Input>
                                        </AreaItem>
                                        <AreaItem>
                                            <SubTitulo>ESTADO</SubTitulo>
                                            <Input type='text' value={estado || ''} onChange={(e)=>setEstado(e.target.value)}></Input>
                                        </AreaItem>
                                    </ContainerItem>
                                        <ContainerItem>
                                            <AreaItem>
                                                <SubTitulo>FORNECEDOR</SubTitulo>
                                                <Select value={fornecedorId || ''} onChange={(e)=>setFornecedorId(e.target.value)}>
                                                    <option key={0} value={0}>Selecionar</option>
                                                    {fornecedores.map((fornecedor)=>{
                                                        return <option key={fornecedor.id} value={fornecedor.id}>{fornecedor.nome}</option>
                                                    })
                                                    }
                                                </Select>
                                            </AreaItem>
                                            <AreaItem>
                                                <SubTitulo>STATUS</SubTitulo>
                                                <Select value={statusSelecionado || ''} onChange={(e)=>setStatusSelecionado(e.target.value)}>
                                                    <option key={1} value='ATIVO'>ATIVO</option>
                                                    <option key={2} value='DESATIVADO'>DESATIVADO</option>
                                                </Select>
                                            </AreaItem>
                                        </ContainerItem>

                                        <ContainerItem>
                                            <AreaItem>
                                                <SubTitulo>PRECO UTILIZADO</SubTitulo>
                                                <Select value={precoSelecionado || ''} onChange={(e)=>setPrecoSelecionado(e.target.value)}>
                                                    <option key={0} value={0}>Selecionar</option>
                                                    {precos.map((preco)=>{
                                                        return <option key={preco.id} value={preco.id}>{preco.nome}</option>
                                                    })
                                                    }
                                                </Select>
                                            </AreaItem>
                                        </ContainerItem>
                            <AreaButton>
                                    <Button onClick={fechar}>VOLTAR</Button>
                                    <Button onClick={editaLoja}>SALVAR</Button>
                            </AreaButton>
                </ContainerConteudo>
            </ContainerModal>
        </Modal>
    )
}