import styled from 'styled-components';

export const LojasContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    margin-top: 15px;

    > h1{
        margin-top: 10px;
    }

    .lojas-background{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #000;
    }
    .loja-item{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 320px;
        max-width: 320px;
        height: 440px;
        box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
            inset -5px -5px 15px rgba(255, 255, 255, 0.1),
            5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        margin: 30px;
        transition: 0.5s;
    }
    .loja-item:hover {
        box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
            inset -5px -5px 15px rgba(255, 255, 255, 0.1),
            5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
        transform: scale(1.05);
    }
    .loja-titulo{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .loja-titulo h1{
        font-size: 15px;
    }

    .loja-conteudo{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 8px;
        font-weight: normal;
    }
    .loja-conteudo > h1 {
        width: 60%;
    }
    .area-button-loja{
        display: flex;
        flex-direction: column;
        padding: 5px;
        align-items: center;
        justify-content: center;
    }

    .area-button-loja a{
        display: flex;
        align-items: center;
        height: 30px;
        border: 0;
        border-radius: 7px;
        background-color: #DC4405;
        font-weight: bold;
        font-size: 10px;
        padding: 5px;
        color: #FFF;
        margin-bottom: 5px;
    }

    .area-button-loja a:hover{
        background-color: #FFF;
        color: #F28F1A;
    }

    @media screen and (max-width:700px) {
        .loja-item{
            width: 80%;
            height: 85%;
        }
    }
`