import { useState,useContext,useEffect, useRef } from 'react';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,AreaItem,SubTitulo,Select,Input,Button,AreaButton} from './estilo.js';
import { AuthContext } from '../../../contexts/auth.js';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import {AdicionarLojaContext} from '../../../contexts/adicionarLoja';
import ErrorsTr from '../../ErrorsTr/index.js';
import ErrosTable from '../../ErrorsTable/index.js';
import { IoChevronBackOutline } from "react-icons/io5";
import criarLojasXlsx from "../../../planilhasDeExemplo/criar_lojas.xlsx";
import UploadXlsx from '../../UploadXlsx/index.js';

export default function InserirLoja({fechar}){
    const [nome, setNome] = useState('')
    const [centroCusto,setCentroCusto] = useState('')
    const [varejo, setVarejo] = useState('')
    const [dataAbertura,setDataAbertura] = useState('')
    const [liderSenior, setLiderSenior] = useState('')
    const [telefoneS, setTelefoneS] = useState('')
    const [liderDistrital, setLiderDistrital] = useState('')
    const [telefoneD, setTelefoneD] = useState('')
    const [cnpj,setCnpj] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [cep,setCep] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [fornecedores, setFornecedores] = useState([])
    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(0)
    const [precoLoja, setPrecoLoja] = useState([])
    const [precoLojaSelecionado, setPrecoLojaSelecionado] = useState(0)
    const [statusSelecionado, setStatusSelecionado] = useState('')
    const [errorsMsg,setErrorsMsg] = useState('');

    const [loading,setLoading] = useState(false);
    const {user,signOut} = useContext(AuthContext);
    const {cnpjMask,cepMask,register,handleSubmit,errors} = useContext(AdicionarLojaContext);
    const componentMounted = useRef(true); 

    useEffect(()=>{
        //BUSCA FORNECEDOR
        if (componentMounted.current){ 
            buscaFornecedores()
            buscaPreco()
        }
        return () => {
            componentMounted.current = false;
        }
    },[user.token,signOut])

    function buscaFornecedores(){
        setLoading(true)
        api.get('/fornecedores',
        {
            headers:{
                'Authorization':user.token
            }
        })
        .then((resp)=>{
            setFornecedores(resp.data.fornecedores)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }
    function buscaPreco(){
        api.get('/precos_produto',
        {
            headers:{
                'Authorization':user.token
            }
        })
        .then((resp)=>{
            setPrecoLoja(resp.data.precos)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }

    function adicionaLoja(data){
        setLoading(true)
        api.post('/lojas/cadastro',
            {
                nome:data.loja,
                centroCusto:data.centroCusto,
                varejo:data.varejo,
                dataAbertura:dataAbertura,
                liderSenior:liderSenior,
                telefoneS:telefoneS,
                liderDistrital:liderDistrital,
                telefoneD:telefoneD,
                cnpj:data.cnpj,
                endereco:data.endereco,
                bairro:data.bairro,
                cep:data.cep,
                cidade:data.cidade,
                estado:data.estado.toUpperCase(),
                status:data.status,
                fornecedor_id:fornecedorSelecionado,
                precos_produto_id:precoLojaSelecionado
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            toast.success(resp.data.response.mensagem)
            setLoading(false)
            window.location.reload()
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }
    
    function criarLojas(results){
        const lojas = results.data;
        if(user.token){
            api.post('/lojas/cadastros', {lojas}, {
                headers:{
                    'Authorization': user.token
                }
            }).then((resp) => {
                toast.success(resp.data.mensagem)
                window.location.reload()
            }).catch((error) => {
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                } else if (error.response.status === 406) {
                    toast.info(error.response.data.error)
                } else if (error.response.status === 413){
                    toast.info('Quantidade de dados muito grande.');
                } else {
                    toast.info("Erro ao criar lojas.");
                    setErrorsMsg(error.response.data.errors);
                }
            })
        }
    } 
    
    return(
        <Modal>
            {errorsMsg ? (
                <ContainerModal>
                    <h1 className='errors-title'>Lista de erros</h1>
                    <div className='errors-table-container'>
                        <ErrosTable titleColumns={['mensagem','item','valor']}>
                            {errorsMsg.map((erro) => {
                                return <ErrorsTr erro={erro}/>
                            })}
                        </ErrosTable>
                    </div>
                    <button className='errors-button' onClick={(e) => setErrorsMsg('')}>
                        <IoChevronBackOutline size={20}/>
                        VOLTAR
                    </button>
                </ContainerModal>
            ) : (
                <ContainerModal>
                    <Titulo>INSERIR LOJA</Titulo>
                    <div className='upload-container'>
                        <UploadXlsx resultCallback={criarLojas} planilhaDeExemplo={criarLojasXlsx}/>
                    </div>
                    <ContainerConteudo onSubmit={handleSubmit(adicionaLoja)}>
                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>LOJA*</SubTitulo>
                                <Input type='text' value={nome} {...register('loja',{onChange:(e)=>setNome(e.target.value)})}/>
                                {errors.loja && <p style={{color:'red'}}>{errors.loja.message}</p>}
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>Centro Custo*</SubTitulo>
                                <Input type='number' value={centroCusto} {...register('centroCusto',{onChange:(e)=>setCentroCusto(e.target.value)})}/>
                                {errors.centroCusto && <p style={{color:'red'}}>{errors.centroCusto.message}</p>}
                            </AreaItem>
                        </ContainerItem>

                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>VAREJO*</SubTitulo>
                                    <Input type='text' value={varejo} {...register('varejo',{onChange:(e)=> setVarejo(e.target.value)})}/>
                                    {errors.varejo && <p style={{color:'red'}}>{errors.varejo.message}</p>}
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>CNPJ*</SubTitulo>
                                    <Input type='text' value={cnpj} {...register('cnpj',{onChange:(e)=>setCnpj(cnpjMask(e.target.value))})}/>
                                    {errors.cnpj && <p style={{color:'red'}}>{errors.cnpj?.message}</p>}
                                </AreaItem>
                            </ContainerItem>
                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>LIDER SENIOR</SubTitulo>
                                    <Input type='text' value={liderSenior} onChange={(e)=>setLiderSenior(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>TELEFONE SENIOR</SubTitulo>
                                    <Input type='tel' value={telefoneS} onChange={(e)=>setTelefoneS(e.target.value)}></Input>
                                </AreaItem>
                            </ContainerItem>

                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>LIDER DISTRITAL</SubTitulo>
                                    <Input type='text' value={liderDistrital} onChange={(e)=>setLiderDistrital(e.target.value)}></Input>
                                </AreaItem>
                                <AreaItem>
                                    <SubTitulo>TELEFONE DISTRITAL</SubTitulo>
                                    <Input type='tel' value={telefoneD} onChange={(e)=>setTelefoneD(e.target.value)}></Input>
                                </AreaItem>
                            </ContainerItem>
                                <ContainerItem>
                                    <AreaItem>
                                        <SubTitulo>DATA DE ABERTURA</SubTitulo>
                                        <Input type='date' value={dataAbertura} onChange={(e)=>setDataAbertura(e.target.value)}></Input>
                                    </AreaItem>
                                    <AreaItem>
                                        <SubTitulo>CEP*</SubTitulo>
                                        <Input type='text' value={cep} {...register('cep',{onChange:(e)=>setCep(cepMask(e.target.value))})}/>
                                        {errors.cep && <p style={{color:'red'}}>{errors.cep?.message}</p>}
                                    </AreaItem>
                                </ContainerItem>

                                    <ContainerItem>
                                        <AreaItem>
                                            <SubTitulo>ENDEREÇO*</SubTitulo>
                                            <Input type='text' value={endereco} {...register('endereco',{onChange:(e)=>setEndereco(e.target.value)})}/>
                                            {errors.endereco && <p style={{color:'red'}}>{errors.endereco.message}</p>}
                                        </AreaItem>
                                        <AreaItem>
                                            <SubTitulo>BAIRRO*</SubTitulo>
                                            <Input type='text' value={bairro} {...register('bairro',{onChange:(e)=>setBairro(e.target.value)})}/>
                                            {errors.bairro && <p style={{color:'red'}}>{errors.bairro.message}</p>}
                                        </AreaItem>
                                    </ContainerItem>

                                        <ContainerItem>
                                            <AreaItem>
                                                <SubTitulo>CIDADE*</SubTitulo>
                                                <Input type='text' value={cidade} {...register('cidade',{onChange:(e)=>setCidade(e.target.value)})}/>
                                                {errors.cidade && <p style={{color:'red'}}>{errors.cidade.message}</p>}
                                            </AreaItem>
                                            <AreaItem>
                                                <SubTitulo>ESTADO*</SubTitulo>
                                                <Input type='text' value={estado} {...register('estado',{onChange:(e)=>setEstado(e.target.value)})}/>
                                                {errors.estado && <p style={{color:'red'}}>{errors.estado.message}</p>}
                                            </AreaItem>
                                        </ContainerItem>

                                            <ContainerItem>
                                                <AreaItem>
                                                    <SubTitulo>FORNECEDOR*</SubTitulo>
                                                    <Select type='number' value={fornecedorSelecionado} {...register('fornecedor',{onChange:(e)=>setFornecedorSelecionado(e.target.value)})}>
                                                        <option key={0} value={0}>Selecionar</option>
                                                        {fornecedores.map((fornecedor,index)=>{
                                                            return <option key={index} value={fornecedor.id}>{fornecedor.id} - {fornecedor.nome}</option>
                                                        })
                                                        }
                                                    </Select>
                                                    {errors.fornecedor && <p style={{color:'red'}}>{errors.fornecedor.message}</p>}
                                                </AreaItem>
                                                <AreaItem>
                                                    <SubTitulo>CEP FORNECEDOR</SubTitulo>
                                                    <Select value={fornecedorSelecionado} onChange={(e)=>setFornecedorSelecionado(e.target.value)}>
                                                        {fornecedores.map((fornecedor,index)=>{
                                                            return <option key={index} value={fornecedor.id}>{fornecedor.cep}</option>
                                                        })
                                                        }
                                                    </Select>
                                                </AreaItem>
                                            </ContainerItem>
                                            <ContainerItem>
                                                <AreaItem>
                                                    <SubTitulo>STATUS*</SubTitulo>
                                                    <Select value={statusSelecionado} {...register('status',{onChange:(e)=>setStatusSelecionado(e.target.value)})}>
                                                        <option key={1} value='ATIVO'>ATIVO</option>
                                                        <option key={2} value='DESATIVADO'>DESATIVADO</option>
                                                    </Select>
                                                    {errors.status && <p style={{color:'red'}}>{errors.status.message}</p>}
                                                </AreaItem>
                                                <AreaItem>
                                                <SubTitulo>PRECO UTILIZADO*</SubTitulo>
                                                <Select value={precoLojaSelecionado} {...register('precoLoja',{onChange:(e)=>setPrecoLojaSelecionado(e.target.value)})}>
                                                    <option key={0} value={0}>Selecionar</option>
                                                    {precoLoja.map((preco)=>{
                                                        return <option key={preco.id} value={preco.id}>{preco.id} - {preco.nome}</option>
                                                    })
                                                    }
                                                </Select>
                                                {errors.precoLoja && <p style={{color:'red'}}>{errors.precoLoja.message}</p>}
                                            </AreaItem>
                                            </ContainerItem>
                                <AreaButton>
                                        <Button onClick={fechar}>VOLTAR</Button>
                                        <Button type='submit'>SALVAR</Button>
                                </AreaButton>
                    </ContainerConteudo>
                </ContainerModal>
            )}
        </Modal>
    )
}