import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import { createContext } from 'react';

export const AdicionarAcessoContext = createContext({});

export default function AdicionarAcessoProvider({children}){

    const schema = yup.object().shape({
        usuarioId: yup.number().min(1,'Minimo 1 item'),
        lojaId: yup.number().min(1,'Minimo 1 item'),
        fornecedorId: yup.number().min(1,'Minimo 1 item'),
        
    })

    const { register, handleSubmit, reset , formState: {errors} } = useForm({
        resolver: yupResolver(schema)
    })

    return(
        <AdicionarAcessoContext.Provider value={{register,handleSubmit,reset,errors}}>
            {children}
        </AdicionarAcessoContext.Provider>
    )
}
