import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify";
import { AuthContext } from "../../../contexts/auth";
import api from "../../../services/api";
import InputPesquisa from "../../InputPesquisa";
import {IoAlertCircleOutline} from 'react-icons/io5';
import PedidoTr from "../../Tr/PedidoTr";
import Tabela from "../../Tabela";
import { AreaHeader,ContainerNull, AreaPesquisa,AreaFiltros,TituloFiltros, FiltroData,SelectPedidos, Container, ContainerPedidosFornecedor, ContainerTabela, Titulo} from "./estilo";
import RelatorioLink from '../../RelatorioLink';

export default function PedidosAdm(){
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);
    const [pedidos, setPedidos] = useState([]);
    const [listaDePedidos, setListaDePedidos] = useState([]);
    const pedidosFields = ['codigoLoja','loja','endLoja','idPedido','produto','statusPedido','statusFornecedor','tipoEntrega','quantidadePaginas','observacao','formato','fornecedor','emailFornecedor','endFornecedor','dataCriacao','horaCriacao','dataAtualizacao'];
    const [data1Selecionada,setData1Selecionada] = useState('2023-00') 
    const [data2Selecionada,setData2Selecionada] = useState('2023-00') 
    const [tipoPedido,setTipoPedido] = useState(0)
    const [opcoesPedido,setOpcoesPedido] = useState([
        {id:0,nome:'Selecione',valor:0},
        {id:1,nome:'Via fornecedor',valor:1},
        {id:2,nome:'Web',valor:2},
        {id:3,nome:'Aplicativo',valor:3},
    ])

    function buscaPedidos(){
        // setLoading(true)
        const data1Convertida = data1Selecionada.split('-').reverse().join('/')
        const data2Convertida = data2Selecionada.split('-').reverse().join('/')
        api.get(`/pedidos`,
            {
                params:{
                    data1: encodeURI(data1Convertida),
                    data2: encodeURI(data2Convertida),
                    tipo:tipoPedido
                },
                headers:{
                    'Authorization':user.token,
                },
            }
        ).then((response) => {
            setPedidos(response.data.pedidos)
            setListaDePedidos(response.data.pedidos)
            setLoading(false)
        }).catch((error) => {
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }

    useEffect(()=>{
        if(data1Selecionada !== '2023-00' && data2Selecionada !== '2023-00' && tipoPedido != 0){
            buscaPedidos()
        }
    },[tipoPedido, data1Selecionada, data2Selecionada])

    function filtroDePedidos(lista, valor){
        return lista.filter((pedido) => String(pedido.idPedido).includes(valor) || pedido.statusFornecedor.toUpperCase().includes(valor.toUpperCase()) || pedido.formato.toUpperCase().includes(valor.toUpperCase()) || pedido.loja.toUpperCase().includes(valor.toUpperCase()) || pedido.tipoEntrega.toUpperCase().includes(valor.toUpperCase()));
    }
    if(loading){
        return(
            <Container>
                <ContainerPedidosFornecedor>
                    <Titulo>Carregando...</Titulo>
                </ContainerPedidosFornecedor>
            </Container>
        )
    }
    return (
        <Container>
            <ContainerPedidosFornecedor>
                <Titulo>LISTA DE PEDIDOS</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={pedidos} setLista={setListaDePedidos} funcaoDeFiltro={filtroDePedidos}/>
                    </AreaPesquisa>
                    <RelatorioLink dados={listaDePedidos} fields={pedidosFields} filename={"pedidos"}/>
                </AreaHeader>
                    <AreaFiltros>
                        <TituloFiltros>Filtros: </TituloFiltros>
                        <SelectPedidos onChange={(e)=>setTipoPedido(e.target.value)}>
                            {opcoesPedido.map((opcao)=> {
                                return <option key={opcao.id} value={opcao.valor} label={opcao.nome}></option>
                            })}
                        </SelectPedidos>
                        <FiltroData value={data1Selecionada} type={'month'} onChange={(e)=>setData1Selecionada(e.target.value)}/>
                        <FiltroData value={data2Selecionada} type={'month'} onChange={(e)=>setData2Selecionada(e.target.value)}/>
                    </AreaFiltros>
                {listaDePedidos.length === 0 ? (
                    <ContainerNull>
                        <IoAlertCircleOutline size={50} color='#FF0000'/>
                        <h1>Nenhum item encontrado...</h1>
                    </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela titleColumns={['Pedido','Codigo Loja','Status do Fornecedor','Tipo de Entrega','Páginas','Loja','Formato']}>
                                {listaDePedidos.map((pedido, index)=>{
                                    return <PedidoTr key={index} pedido={pedido} buscaPedidos={buscaPedidos} Admin/>
                                })}
                            </Tabela>
                        </ContainerTabela>
                    )
                }
            </ContainerPedidosFornecedor>
        </Container>
    )
}