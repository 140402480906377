import {  TR } from "./estilo";

export default function PrecoExecutadoTr({items,Admin}){

    return (
        <TR key={items.id}>
            {Admin && <td data-label='pedido'>{items.pedidoId}</td>}
            
            {Admin && <td data-label='produto'>{items.produto}</td>}  
                
            {Admin && <td data-label='preco-oficial'>{items.preco_oficial}</td>}

            {Admin && <td data-label='preco-executado'>{items.preco_executado}</td>}
            
            {Admin && (
                <>
                    <td data-label='loja'>{items.loja}</td>
                    <td data-label='data-criacao'>{items.dataCriacao}</td>
                </>
            )}
        </TR>
    )
}