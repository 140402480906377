import { StyledTr } from "./estilo";

export default function ErrorsTr({erro}){
    return (
        <StyledTr>
            <td className="error-td"><p>{erro.mensagem}</p></td>
            <td className="error-td"><p>{erro.item}</p></td>
            <td className="error-td"><p>{erro.valor ? erro.valor : 'Sem conteúdo.'}</p></td>
        </StyledTr>
    )
}