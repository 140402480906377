import Header from '../../components/Header';
import Lojas from '../../components/Lojas';
import { useState,useEffect,useContext, useCallback, useRef} from 'react';
import api from '../../services/api';
import {toast} from 'react-toastify';
import {AuthContext} from '../../contexts/auth';
import {Helmet} from 'react-helmet';
import { HomeContainer } from './estilo';
import { AvaliacaoChamadoModal } from '../../components/modals/ModalAvaliacaoChamado';

export default function Home() {
  const [lojas,setLojas] = useState([])
  const [load,setLoad] = useState(false);
  const {user,signOut} = useContext(AuthContext);
  const [dataOferecimentoAvaliacao, setDataOferecimentoAvaliacao] = useState(false);
  const componentMounted = useRef(true); 

  const[avaliacao, setAvaliacao] = useState(false);

  function verificarAvaliacao() {
    if(!dataOferecimentoAvaliacao) {
        return setAvaliacao(true)
    }
    
    const [dia, mes, ano] = dataOferecimentoAvaliacao.split("/");
    const dataOferecimentoAvalaiacao = new Date(ano, mes - 1, dia);
    const dataAtual = new Date();

    if (dataAtual > dataOferecimentoAvalaiacao) {
        setAvaliacao(true);
    }
  }

  function buscarDadosUsuario(){
    api.get(`/usuarios/${user.id_usuario}`,
        {
          headers:{
            'Authorization':user.token
          }
        }
      ).then((resp)=>{
        const data = resp.data.mensagem.usuarios[0].data_oferecimento_avaliacao
        setDataOferecimentoAvaliacao(data)
      }
    ).catch((error)=>{
        console.log(error)
    }
    )
  }

  useEffect(() => {
    buscarDadosUsuario()
  }, [])

  useEffect(() => {
    if(user.id_usuario && dataOferecimentoAvaliacao !== false){
        verificarAvaliacao();
    }
  }, [user, dataOferecimentoAvaliacao])

  const buscaLojas = useCallback(() => {
    setLoad(true)
    api.get(`/lojas/lojasUsuario/${user.id_usuario}`,
        {
          headers:{
            'Authorization':user.token
          }
        }
      ).then((resp)=>{
        setLojas(resp.data.lojas)
        setLoad(false)
      }).catch((error)=>{
        if(error.response.status === 401){
          toast.info(error.response.data.error)
          signOut()
        }else{
          toast.error(error.response.data.error)
          setLoad(false)
        }
      }
    )
  },[user.token,user.id_usuario,signOut]);
  useEffect(()=>{
	if (componentMounted.current){ 
		buscaLojas()
	}
	return () => {
		componentMounted.current = false;
	}
  },[buscaLojas])

    if(load === true){
      return(
        <HomeContainer>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>Escolher Loja</title>
			</Helmet>
			<Header nome='Lojas disponiveis'/>
			<div className='area-loja'>
			<h1>Carregando...</h1>
			</div>
      	</HomeContainer>
      )
    }

    return (
      	<HomeContainer>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>Escolher Loja</title>
			</Helmet>
			<Header nome='Lojas disponiveis'/>
			<div className='area-loja'>
			<Lojas lj={lojas}/>
        	</div>
          {avaliacao && <AvaliacaoChamadoModal setStateModal={setAvaliacao}/>}
     	 </HomeContainer>
    );
  }
