import HeaderLateral from "../../components/HeaderLateral";
import Categoriasdash from "../../components/Dash/Categoriasdash";
import './categorias.css';
import {Helmet} from 'react-helmet';

export default function Usuarios(){
    return(
        <div className="categoriascontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Categorias</title>
            </Helmet>
            <HeaderLateral/>
            <Categoriasdash/>
        </div>
    )
}