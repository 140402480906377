import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {cnpj} from 'cpf-cnpj-validator';
import { createContext } from 'react';

export const AdicionarLojaContext = createContext({});

export default function AdicionarLojaProvider({children}){

    function cnpjMask(value){
        return value
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

    function cepMask(value){
        return value
        .replace(/\D/g,'')
        .replace(/(\d{5})(\d{3})/,"$1-$2");
    }
    yup.addMethod(yup.string, "cnpjValidate", function (errorMessage) {
        return this.test(`cnpj-validate`, errorMessage, function (value) {
            const cnpjNumber = value.replace('.', '').replace('.', '').replace('/','').replace('-', '')
            if(cnpj.isValid(cnpjNumber)){
                return value
            }
            const { path, createError } = this;
        
            return createError({ path, message: errorMessage })
        });
    });
    yup.addMethod(yup.string, "cepValidate", function (errorMessage) {
        return this.test(`cep-validate`, errorMessage, function (value) {
            const cepNumber = value.replace('.','').replace('-','');
            var validacep = /^[0-9]{8}$/;
            if(validacep.test(cepNumber)){
                return value;
            }
            const { path, createError } = this;
        
            return createError({ path, message: errorMessage })
        });
    });

    const schema = yup.object().shape({
        loja: yup.string().required('Campo obrigatorio.'),
        centroCusto: yup.string().min(4,'Minimo de 4 caracteres').required('Conter somente numeros!'),
        varejo: yup.string().required('Campo obrigatorio.'),
        cnpj: yup.string().cnpjValidate('Cnpj inválido.').required(),
        cep: yup.string().cepValidate('Cep inválido').required('Campo obrigatorio.'),
        endereco: yup.string().required('Campo obrigatorio.'),
        bairro: yup.string().required('Campo obrigatorio.'),
        cidade: yup.string().required('Campo obrigatorio.'),
        estado: yup.string().max(2,'Maximo de 2 caracteres'),
        fornecedor: yup.number().min(1,'Selecione uma opção.'),
        precoLoja:yup.number().min(1,'Selecione uma opção.'),
        status: yup.string().required('Selecione uma opção'),
    })

    const { register, handleSubmit, reset , formState: {errors} } = useForm({
        resolver: yupResolver(schema)
    })

    return(
        <AdicionarLojaContext.Provider value={{cnpjMask,cepMask,register,handleSubmit,reset,errors}}>
            {children}
        </AdicionarLojaContext.Provider>
    )
}