import HeaderLateral from "../../components/HeaderLateral";
import {Helmet} from 'react-helmet';
import PedidosFornecedor from "../../components/Fornecedor/PedidosFornecedor";

export default function FornecedorPedidos(){
    return(
        <div className="forncontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Pedidos</title>
            </Helmet>
            <HeaderLateral/>
            <PedidosFornecedor/>
        </div>
    )
}