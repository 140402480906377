import { useState,useContext,useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import {AuthContext} from '../../../contexts/auth';
import api from '../../../services/api';
import {ContainerNull,Container,ContainerCategorias,Titulo,AreaHeader,AreaPesquisa,ButtonNovo,ContainerTabela,Tabela,Thead,TR,TH,Tbody,TD,SpanStatus,ButtonEditar} from './estilo';
import AlteraCategoria from '../../modals/Editar_categoria';
import InputPesquisa from '../../InputPesquisa';
import InserirCategoria from '../../modals/Novo_categoria';
import {IoAlertCircleOutline} from 'react-icons/io5';
import RelatorioLink from '../../RelatorioLink';

export default function Categoriasdash(){
    const [modal,setModal] = useState(false);
    const [modalNovo,setModalNovo] = useState(false);
    const [dadosCategoria,setDadosCategoria] = useState('');
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);

    const [categorias,setCategorias] = useState([]);
    const [listaDeCategorias, setListaDeCategorias] = useState([]);
    const categoriasFields = ['id','categoria','status','createdAt','updatedAt'];
    const componentMounted = useRef(true); 

    //busca todos os categorias
    const buscaCategorias = useCallback(() => {
        setLoading(true)
        api.get('/categorias',
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setCategorias(resp.data.categorias)
            setListaDeCategorias(resp.data.categorias)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    },[user.token,signOut])

    useEffect(()=>{
        if (componentMounted.current){
            buscaCategorias()
        }
        return () => {
            componentMounted.current = false;
        }
    },[buscaCategorias])

    function enviaDados(item){
        setModal(!modal);
        setDadosCategoria(item)
    }

    function abreNovo(){
        setModalNovo(!modalNovo);
    }

    function filtroDeCategorias(lista, valor){
        return lista.filter((categoria) => categoria.categoria.toUpperCase().includes(valor.toUpperCase()) 
        || categoria.status.toUpperCase().includes(valor.toUpperCase()));
    }
    if(loading){
        return(
            <Container>
                <ContainerCategorias>
                    <Titulo>Carregando...</Titulo>
                </ContainerCategorias>
            </Container>
        )
    }
    return(
        <Container>
            <ContainerCategorias>
                <Titulo>LISTA DE CATEGORIAS</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={categorias} setLista={setListaDeCategorias} funcaoDeFiltro={filtroDeCategorias}/>
                    </AreaPesquisa>
                    <ButtonNovo onClick={abreNovo}>
                        Novo
                    </ButtonNovo>
                    <RelatorioLink dados={listaDeCategorias} fields={categoriasFields} filename={"categorias"}/>
                </AreaHeader>
                {listaDeCategorias.length === 0 ? (
                        <ContainerNull>
                            <IoAlertCircleOutline size={50} color='#FF0000'/>
                            <h1>Nenhum item encontrado...</h1>
                        </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela>
                                <Thead>
                                    <TR>
                                        <TH scope='col'>Id</TH>
                                        <TH scope='col'>Categoria</TH>
                                        <TH scope='col'>Status</TH>
                                        <TH scope='col'>#</TH>
                                    </TR>
                                </Thead>
                                    <Tbody>
                                            {listaDeCategorias.map((categoria)=>{
                                                    return(
                                                        
                                                            <TR key={categoria.id}>
                                                                <TD data-label='id'>{categoria.id}</TD>
                                                                <TD data-label='usuario'>{categoria.categoria}</TD>
                                                                <TD data-label='status'>
                                                                    <SpanStatus style={{backgroundColor:categoria.status === 'ATIVO' ? '#29C013' : '#FF0000' }}>{categoria.status}</SpanStatus>
                                                                </TD>
                                                                <TD data-label='#'>
                                                                    <ButtonEditar onClick={()=>enviaDados(categoria)}>
                                                                        EDITAR
                                                                    </ButtonEditar>
                                                                </TD>
                                                            </TR>
                                                    )
                                                }
                                            )
                                            }
                                    </Tbody>
                            </Tabela>
                        </ContainerTabela>
                    )
                }
                {
                    modal && (
                        <AlteraCategoria
                        fechar={enviaDados}
                        conteudo={dadosCategoria}
                        atualizaCategoria={buscaCategorias}
                        />
                    )
                }
                {
                    modalNovo && <InserirCategoria
                    fechar={abreNovo}
                    atualizaCategoria={buscaCategorias}
                    />
                }
            </ContainerCategorias>
        </Container>
    )
}