import { useState,useContext,useEffect, useRef } from 'react';
import api from '../../../services/api';
import { AuthContext } from '../../../contexts/auth';
import { toast } from 'react-toastify';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,AreaItem,SubTitulo,Select,Input,Button,AreaButton} from './estilo.js';
import { AdicionarUsuarioContext } from '../../../contexts/adicionarUsuario';
import ErrosTable from '../../ErrorsTable';
import ErrorsTr from '../../ErrorsTr';
import { IoChevronBackOutline } from "react-icons/io5";
import UploadXlsx from '../../UploadXlsx';
import criarUsuariosXlsx from "../../../planilhasDeExemplo/criar_usuarios.xlsx";

export default function NovoUsuario({fechar,atualizaUsuarios}){
    const {user,signOut} = useContext(AuthContext);
    const {cpfMask,register,handleSubmit,reset,errors} = useContext(AdicionarUsuarioContext);
    const [usuario,setUsuario] = useState('')
    const [senha, setSenha] = useState('')
    const [nome, setNome] = useState('')
    const [cpf, setCpf] = useState('')
    const [email, setEmail] = useState('')
    const [dataNasc,setDataNasc] = useState('')
    const [perfils, setPerfils] = useState([])
    const [perfilSelecionado,setPerfilSelecionado] = useState('')
    const [statusSelecionado, setStatusSelecionado] = useState('')
    const [loading,setLoading] = useState(false);
    const [errorsMsg,setErrorsMsg] = useState('');
    const componentMounted = useRef(true); 

    useEffect(()=>{

        //BUSCA PERFILS
        if (componentMounted.current){ 
            buscaPerfils()
        }
        return () => {
            componentMounted.current = false;
        }
    },[user.token,signOut])

    function buscaPerfils(){
        setLoading(true)
        api.get('/perfil',
        {
            headers:{
                'Authorization':user.token
            }
        })
        .then((resp)=>{
            setPerfils(resp.data.perfils)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }

    function adicionaUsuario(data){
        setLoading(true)
        api.post('/usuarios/cadastro',
            {
                usuario:data.usuario,
                senha:data.senha,
                nome:data.nome,
                email:data.email,
                documento:data.cpf,
                data_nascimento:data.dataNasc,
                perfil_id:data.perfil,
                status:data.status
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            toast.success(resp.data.response.mensagem)
            window.location.reload()
            setLoading(false);
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }

    function criarUsuarios(results){
        const usuarios = results.data;
        if(user.token){
            api.post('/usuarios/cadastros', {usuarios}, {
                headers:{
                    'Authorization': user.token
                }
            }).then((resp) => {
                toast.success(resp.data.mensagem)
            }).catch((error) => {
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                } else if (error.response.status === 406) {
                        toast.info(error.response.data.error)
                } else {
                    toast.info("Erro ao criar usuários.");
                    setErrorsMsg(error.response.data.errors);
                }
            })
        }
    } 

    return(
        <Modal>
            {errorsMsg ? (
                <ContainerModal>
                    <h1 className='errors-title'>Lista de erros</h1>
                    <div className='errors-table-container'>
                        <ErrosTable titleColumns={['mensagem','item','valor']}>
                            {errorsMsg.map((erro) => {
                                return <ErrorsTr erro={erro}/>
                            })}
                        </ErrosTable>
                    </div>
                    <button className='errors-button' onClick={(e) => setErrorsMsg('')}>
                        <IoChevronBackOutline size={20}/>
                        VOLTAR
                    </button>
                </ContainerModal>
            ) : (
                <ContainerModal>
                    <Titulo>INSERIR USUÁRIO</Titulo>
                    <div className='upload-container'>
                        <UploadXlsx resultCallback={criarUsuarios} planilhaDeExemplo={criarUsuariosXlsx}/>
                    </div>
                    <ContainerConteudo onSubmit={handleSubmit(adicionaUsuario)}>
                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>USUARIO*</SubTitulo>
                                <Input placeholder='Ex:154798' type='number' value={usuario} {...register('usuario',{onChange:(e)=>setUsuario(e.target.value)})}/>
                                {errors.usuario && <p style={{ color:"red"}}>{errors.usuario.message}</p>}
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>SENHA*</SubTitulo>
                                <Input placeholder='Ex:1234' type='password' value={senha} {...register('senha',{onChange:(e)=>setSenha(e.target.value)})}/>
                                {errors.senha && <p style={{color:'red'}}>{errors.senha.message}</p>}
                            </AreaItem>
                        </ContainerItem>
                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>NOME*</SubTitulo>
                                <Input type='text' value={nome || ''} {...register('nome',{onChange: (e)=>setNome(e.target.value)})}/>
                                {errors.nome && <p style={{color:'red'}}>{errors.nome.message}</p>}
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>CPF</SubTitulo>
                                <Input type='text' value={cpf || ''} {...register('cpf',{onChange:(e)=>setCpf(cpfMask(e.target.value))})}/>
                                {errors.cpf && <p style={{color:'red'}}>{errors.cpf?.message}</p>}
                            </AreaItem>
                        </ContainerItem>
                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>EMAIL</SubTitulo>
                                <Input type='email' value={email || ''} {...register('email',{onChange:(e)=>setEmail(e.target.value)})}/>
                                {errors.email && <p style={{color:'red'}}>{errors.email.message}</p>}
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>DATA DE NASCIMENTO</SubTitulo>
                                <Input type='date' value={dataNasc || ''} {...register('dataNasc',{onChange:(e)=>setDataNasc(e.target.value)})}/>
                                {errors.dataNasc && <p style={{color:'red'}}>{errors.dataNasc.message}</p>}
                            </AreaItem>
                        </ContainerItem>
                        <ContainerItem>
                            <AreaItem>
                                <SubTitulo>PERFIL*</SubTitulo>
                                <Select value={perfilSelecionado || ''} {...register('perfil',{onChange:(e)=>setPerfilSelecionado(e.target.value)})}>
                                    <option key={0} value='0'>SELECIONAR</option>
                                    {perfils.map((perfil)=>{
                                        return <option key={perfil.id} value={perfil.id}>{perfil.id} - {perfil.nome}</option>
                                    })
                                    }
                                </Select>
                                {errors.perfil && <p style={{color:'red'}}>{errors.perfil.message}</p>}
                            </AreaItem>
                            <AreaItem>
                                <SubTitulo>STATUS*</SubTitulo>
                                <Select value={statusSelecionado} {...register('status',{onChange:(e)=>setStatusSelecionado(e.target.value)})}>
                                    <option key={0} value=''>SELECIONAR</option>
                                    <option key={1} value='ATIVO'>ATIVO</option>
                                    <option key={2} value='DESATIVADO'>DESATIVADO</option>
                                </Select>
                                {errors.status && <p style={{color:'red'}}>{errors.status.message}</p>}
                            </AreaItem>
                        </ContainerItem>
                        <AreaButton>
                                <Button onClick={fechar}>VOLTAR</Button>
                                <Button type="submit">SALVAR</Button>
                        </AreaButton>
                    </ContainerConteudo>
                </ContainerModal>
            )}
        </Modal>
    )
}