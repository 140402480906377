import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import { createContext } from 'react';

export const AdicionarProdutoContext = createContext({});

export default function AdicionarProdutoProvider({children}){

    const schema = yup.object().shape({
        codigo:yup.string().required('Campo obrigatório.'),
        produto:yup.string().required('Campo obrigatório.'),
        descricao:yup.string().min(1,'Minimo de caracteres').max(19,'Maximo de caracteres'),
        descricao2:yup.string().max(25,'Maximo de caracteres'),
        peso:yup.string().max(10,'Maximo de caracteres'),
        categoriaId:yup.number().min(1,'Campo obrigatório.'),
        tipoLoja_id:yup.number().min(1,'Selecionar um item'),
        status:yup.string().required('Campo obrigatório.'),
    })

    const { register, handleSubmit, reset , formState: {errors} } = useForm({
        resolver: yupResolver(schema)
    })

    return(
        <AdicionarProdutoContext.Provider value={{register,handleSubmit,reset,errors}}>
            {children}
        </AdicionarProdutoContext.Provider>
    )
}