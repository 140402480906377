import styled from "styled-components";

export const Modal = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background:rgba(0,0,0,0.5);
    z-index:99;
`;

export const ContainerModal = styled.div`
    position:fixed;
    display:flex;
    flex-direction:column;
    border-radius:9px;
    width:800px;
    height:min-content;
    left:0;
    right:0;
    bottom:0;
    top: 0;
    margin: auto;
    background-color:#f7f7f7;
    overflow-y: auto;
    @media(max-width:800px){
        max-width:80%;
        max-height:100%;
    }
`;

export const Titulo = styled.h1`
    color: #FFF;
    background-color:#F28F1A;
    border-top-left-radius:9px;
    border-top-right-radius:9px;
    padding:10px;
    text-align:center;
    @media screen and (max-width:800px) {
        font-size: 22px;
    }
`;

export const ContainerConteudo = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:100% ;
    padding:10px;
    box-shadow: 0 0 20px rgba(0,0,0 0.5);
    @media(max-width:800px){
        margin-top:5%;
    }
`;

export const ContainerItem = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    @media(max-width:800px){
        flex-direction:column;
        align-items: center;
    }
`;

export const ContainerItemSelect = styled(ContainerItem)`
    @media screen and (max-width: 800px){
        flex-direction: row;
        justify-content: space-evenly;
    }
`
export const AreaItem = styled.div`
    display:flex;
    flex-direction:column;
    margin-left:15px;
    width:250px;
    @media(max-width:800px){
        flex-direction:column;
        justify-content:space-between;
        margin-bottom:5px;
        margin-left: 0;
        width:80% ;
    }
`;

export const AreaItemSelect = styled(AreaItem)`
    @media screen and (max-width: 800px) {
        width: 35%;
    }
`

export const AreaButton = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    margin-top:20px;
    margin-bottom: 10px;
`;

export const SubTitulo = styled.span`
    font-size: 20px;
    margin-right:5px;
    margin-bottom:5px;
    font-weight:bold ;
    @media screen and (max-width:800px) {
        font-size: 14px;
    }
`;

export const Select = styled.select`
    font-size:15px;
    border-radius:9px;
    border-color:#F28F1A ;
    padding: 3px;
    width:100%;
    @media(max-width:1400px){
        font-size:12px;
    }
`;

export const Input = styled.input`
    font-size: 20px;
    border-radius:9px;
    margin-right:5px;
    padding-left:5px;
    margin-bottom:20px;
    width:100%;
    @media(max-width:1400px){
        font-size:15px;
    }
`;

export const Button = styled.button`
    margin-right:5px;
    padding: 10px;
    width: 80px;
    height:35px;
    background-color: #00A8FF;
    border:0;
    border-radius:9px;
    color:#FFF;
    font-weight:bold ;
`;