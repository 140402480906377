import HeaderLateral from "../../components/HeaderLateral";
import Fornecedoresdash from "../../components/Dash/Fornecedoresdash";
import './fornecedores.css';
import {Helmet} from 'react-helmet';

export default function Fornecedores(){
    return(
        <div className="forncontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Fornecedores</title>
            </Helmet>
            <HeaderLateral/>
            <Fornecedoresdash/>
        </div>
    )
}