import HeaderLateral from "../../components/HeaderLateral";
import {Helmet} from 'react-helmet';
import PedidosAdm from "../../components/Dash/PedidosAdm";

export default function AdmPedidos(){
    return(
        <div className="forncontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Pedidos</title>
            </Helmet>
            <HeaderLateral/>
            <PedidosAdm />
        </div>
    )
}