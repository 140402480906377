import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import { AuthContext } from "../../../contexts/auth";
import api from "../../../services/api";
import InputPesquisa from "../../InputPesquisa";
import {IoAlertCircleOutline} from 'react-icons/io5';
import PedidoTr from "../../Tr/PedidoTr";
import Tabela from "../../Tabela";
import { AreaHeader,ContainerNull, AreaPesquisa, Container, ContainerPedidosFornecedor, ContainerTabela, Titulo} from "./estilo";
import RelatorioLink from "../../RelatorioLink";

export default function PedidosFornecedor(){
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(true);
    const [pedidos, setPedidos] = useState([]);
    const [listaDePedidos, setListaDePedidos] = useState([]);
    const pedidosFields = ['id','produto','statusFornecedor','tipoEntrega','paginas','loja','cidade','estado'];
    const componentMounted = useRef(true); 

    const buscaPedidos = useCallback(() => {
        api.get(`/pedidos/fornecedor/pedido/?usuario_id=${user.id_usuario}`,
            {
                headers:{
                    'Authorization':user.token,
                },
            }
        ).then((response) => {
            setPedidos(response.data.produtos)
            setListaDePedidos(response.data.produtos)
            setLoading(false)
        }).catch((error) => {
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        });
    },[user.token, user.id_usuario,signOut]);

    useEffect(()=>{
        if (componentMounted.current){ 
            buscaPedidos()
        }
        return () => {
            componentMounted.current = false;
        }
    },[buscaPedidos]);

    function filtroDePedidos(lista, valor){
        return lista.filter((pedido) => pedido.produto.toUpperCase().includes(valor.toUpperCase()) || pedido.cidade.toUpperCase().includes(valor.toUpperCase()) || pedido.estado.toUpperCase().includes(valor.toUpperCase()));
    }
    if(loading){
        return(
            <Container>
                <ContainerPedidosFornecedor>
                    <Titulo>Carregando...</Titulo>
                </ContainerPedidosFornecedor>
            </Container>
        )
    }

    return (
        <Container>
            <ContainerPedidosFornecedor>
                <Titulo>LISTA DE PEDIDOS</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={pedidos} setLista={setListaDePedidos} funcaoDeFiltro={filtroDePedidos}/>
                    </AreaPesquisa>
                    <RelatorioLink dados={listaDePedidos} fields={pedidosFields} filename={"pedidos"}/>
                </AreaHeader>
                {listaDePedidos.length === 0 ? (
                    <ContainerNull>
                        <IoAlertCircleOutline size={50} color='#FF0000'/>
                        <h1>Nenhum item encontrado...</h1>
                    </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela titleColumns={['ID','Produto','Status do Fornecedor','Observação','Estado','Páginas','PDF','#']}>
                                {listaDePedidos.map((pedido)=>{
                                    return <PedidoTr key={pedido.id} pedido={pedido} buscaPedidos={buscaPedidos}/>
                                })}
                            </Tabela>
                        </ContainerTabela>
                    )
                }
            </ContainerPedidosFornecedor>
        </Container>
    )
}