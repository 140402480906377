import HeaderLateral from "../../components/HeaderLateral";
import Acessosdash from "../../components/Dash/Acessosdash";
import './acessos.css';
import {Helmet} from 'react-helmet';

export default function Usuarios(){
    return(
        <div className="acessoscontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Acessos</title>
            </Helmet>
            <HeaderLateral/>
            <Acessosdash/>
        </div>
    )
}