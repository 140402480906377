import { useState,useContext,useEffect, useRef, useCallback } from 'react';
import {ContainerNull,Container,ContainerFornecedores,Titulo,AreaHeader,AreaPesquisa,ButtonNovo,ContainerTabela,Tabela,Thead,TR,TH,Tbody,TD,SpanStatus,ButtonEditar} from './estilo';
import {AuthContext} from '../../../contexts/auth';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import AlteraFornecedor from '../../modals/Editar_fornecedor';
import InputPesquisa from '../../InputPesquisa';
import {IoAlertCircleOutline} from 'react-icons/io5';
import RelatorioLink from '../../RelatorioLink';
import ModalNovoFornecedor from '../../modals/Novo_fornecedor';

export default function Fornecedoresdash(){
    const [modal,setModal] = useState(false);
    const [modalNovo,setModalNovo] = useState(false);
    const [dadosFornecedor,setDadosFornecedor] = useState('');
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);

    const [fornecedores,setFornecedores] = useState([]);
    const [listaDeFornecedores, setListaDeFornecedores] = useState([]);
    const fornecedoresFields = ['id','nome','cnpj','email','cidade','estado','endereco','cep','status','createdAt','updatedAt','tipoEntrega']
    const componentMounted = useRef(true); 

    //Busca todos os fornecedores
    const buscaFornecedores = useCallback(() => {
        setLoading(true)
        api.get('/fornecedores',
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setFornecedores(resp.data.fornecedores)
            setListaDeFornecedores(resp.data.fornecedores)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }, [user.token, signOut]);

    useEffect(()=>{
        if (componentMounted.current){ 
            buscaFornecedores()
        }
        return () => {
            componentMounted.current = false;
        }
    },[buscaFornecedores])

    function enviaDados(item){
        setModal(!modal);
        setDadosFornecedor(item);
    }
    
    function filtroDeFornecedores(lista, valor){
        return lista.filter((fornecedor) => fornecedor.nome.toUpperCase().includes(valor.toUpperCase()) || fornecedor.estado.toUpperCase().includes(valor.toUpperCase()));
    }
    if(loading){
        return(
            <Container>
                <ContainerFornecedores>
                    <Titulo>Carregando...</Titulo>
                </ContainerFornecedores>
            </Container>
        )
    }
    return(
        <Container>
            <ContainerFornecedores>
                <Titulo>LISTA DE FORNECEDORES</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={fornecedores} setLista={setListaDeFornecedores} funcaoDeFiltro={filtroDeFornecedores}/>
                    </AreaPesquisa>
                    <ButtonNovo onClick={() => setModalNovo(true)}>
                        Novo
                    </ButtonNovo>
                    <RelatorioLink dados={listaDeFornecedores} fields={fornecedoresFields} filename={"fornecedores"}/>
                </AreaHeader>
                {listaDeFornecedores.length === 0 ? (
                    <ContainerNull>
                        <IoAlertCircleOutline size={50} color='#FF0000'/>
                        <h1>Nenhum item encontrado...</h1>
                    </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela>
                                <Thead>
                                    <TR>
                                        <TH scope='col'>Id</TH>
                                        <TH scope='col'>Fornecedores</TH>
                                        <TH scope='col'>cidade</TH>
                                        <TH scope='col'>Estado</TH>
                                        <TH scope='col'>Procedimento</TH>
                                        <TH scope='col'>Status</TH>
                                        <TH scope='col'>#</TH>
                                    </TR>
                                </Thead>
                                    <Tbody>
                                            {listaDeFornecedores.map((fornecedor)=>{
                                                    return(
                                                        
                                                            <TR key={fornecedor.id}>
                                                                <TD data-label='id'>{fornecedor.id}</TD>
                                                                <TD data-label='usuario'>{fornecedor.nome}</TD>
                                                                <TD data-label='cidade'>{fornecedor.cidade}</TD>
                                                                <TD data-label='estado'>{fornecedor.estado}</TD>
                                                                <TD data-label='procedimento'>{fornecedor.tipoEntrega}</TD>
                                                                <TD data-label='status'>
                                                                    <SpanStatus style={{backgroundColor:fornecedor.status === 'ATIVO' ? '#29C013' : '#FF0000'}}>{fornecedor.status}</SpanStatus>
                                                                </TD>
                                                                <TD data-label='#' style={{width:'190px'}}>
                                                                    <ButtonEditar onClick={()=> enviaDados(fornecedor)}>
                                                                        EDITAR
                                                                    </ButtonEditar>
                                                                </TD>
                                                            </TR>
                                                    )
                                                }
                                            )
                                            }
                                    </Tbody>
                            </Tabela>
                        </ContainerTabela>
                    )
                }
                {modal && (
                    <AlteraFornecedor
                    fechar={enviaDados}
                    conteudo={dadosFornecedor}
                    atualizaFornecedores={buscaFornecedores}
                    />
                )}
                {modalNovo && (
                    <ModalNovoFornecedor
                        fechar={()=> setModalNovo(false)}
                    />
                )}
            </ContainerFornecedores>
        </Container>
    )
}