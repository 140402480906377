import { IoReceiptOutline,IoLogOutOutline,IoHomeOutline,IoSpeedometerOutline,IoPersonOutline,IoBuildOutline,IoStorefrontOutline,IoBagCheckOutline,IoGridOutline,IoArchiveOutline,IoKeyOutline,IoDocumentsOutline} from "react-icons/io5";
import { Link } from "react-router-dom";
import img from '../../img/swift-branco.svg';
import { useContext, useState } from 'react';
import {AuthContext} from '../../contexts/auth';
import PermissaoGlobal from '../../PermissaoGlobal';
import { HiOutlineClipboardList, HiMenu } from "react-icons/hi";
import { Background, MenuMobile, SidebarContainer } from './estilo';
import { VscTriangleRight } from "react-icons/vsc";

export default function HeaderLateral(){
		const {signOut,permissao} = useContext(AuthContext);
		// const [loja,setLoja] = useState('');
		const [statusB, setStatusB] = useState(false);
		const [statusForn, setStatusForn] = useState(false);
		const [statusRelatorio,setStatusRelatorio] = useState(false);
		const [menuMobile, setMenuMobile] = useState(false);

		return(
				<>
				<MenuMobile>
						<HiMenu size={40} color={'white'} onClick={() => setMenuMobile(!menuMobile)}/>
				</MenuMobile>
					<SidebarContainer className="sidebarContainer" menuMobile={menuMobile} menuAdmOpened={statusB}>
							<div>
							<MenuMobile>
									<HiMenu size={40} color={'white'} onClick={() => setMenuMobile(!menuMobile)}/>
							</MenuMobile>
							</div>
							<div className="img-container">
									<img src={img} alt='logo'/>
							</div>
							<PermissaoGlobal permission={permissao} acceptedPermissions={['USER','ADMIN']}>
									<Link to='/dashboard'>
											<IoSpeedometerOutline/>
											Dashboard
									</Link>
							</PermissaoGlobal>
							<PermissaoGlobal permission={permissao} acceptedPermissions={['USER','ADMIN']}>
									<Link to='/home'>
											<IoHomeOutline/>
											Home
									</Link>
							</PermissaoGlobal>
							<PermissaoGlobal permission={permissao} acceptedPermissions={['USER','ADMIN']}>
									<Link to='/solicitarpedidos'>
											<IoReceiptOutline/>
											Solicitar Pedido
									</Link>
							</PermissaoGlobal>
							<PermissaoGlobal permission={permissao} acceptedPermissions={['ADMIN']}>
									<button onClick={()=> setStatusB(!statusB)} className='menuButton'>
											<IoBuildOutline/>
											Admin
											<VscTriangleRight className="icone-triangulo"/>
									</button>
							</PermissaoGlobal>
							<nav
									className={`menu ${statusB ? 'active' : 'inactive'}`}
							>
									<ul>
											<li>
													<Link to='/admin/usuarios'>
															<IoPersonOutline/>
															Usuarios
													</Link>
											</li>
											<li>
													<Link to='/admin/lojas'>
															<IoStorefrontOutline/>
															Lojas
													</Link>
											</li>
											<li>
													<Link to='/admin/produtos'>
													<IoBagCheckOutline/>
															Produtos
													</Link>
											</li>
											<li>
													<Link to='/admin/pedidos'>
															<HiOutlineClipboardList/>
															Pedidos
													</Link>
											</li>
											<li>
													<Link to='/admin/categorias'>
															<IoGridOutline/>
															Categorias
													</Link>
											</li>
											<li>
													<Link to='/admin/fornecedores'>
															<IoArchiveOutline/>
															Fornecedores
													</Link>
											</li>
											<li>
													<Link to='/admin/acessos'>
															<IoKeyOutline/>
															Acessos
													</Link>
											</li>
									</ul>
							</nav>
							<PermissaoGlobal permission={permissao} acceptedPermissions={['ADMIN']}>
									<button onClick={()=> setStatusRelatorio(!statusRelatorio)} className='menuButton'>
											<IoDocumentsOutline/>
											Relatorios
											<VscTriangleRight className="icone-triangulo"/>
									</button>
							</PermissaoGlobal>
							<nav
									className={`menu ${statusRelatorio ? 'active' : 'inactive'}`}
							>
									<ul>
										<li>
											<Link to='/admin/relatorios/precos_executados'>
													<HiOutlineClipboardList/>
													Pedidos x Preço
											</Link>
										</li>
									</ul>
							</nav>
							<PermissaoGlobal permission={permissao} acceptedPermissions={['FORN']}>
									<button onClick={() => setStatusForn(!statusForn)} className={'menuButton'}>
											<IoArchiveOutline/>
											Fornecedor
									</button>
							</PermissaoGlobal>
							<nav
									className={`menu ${statusForn ? 'active' : 'inactive'}`}
							>
									<ul>
											<li>
													<Link to='/fornecedor/pedidos'>
															<IoReceiptOutline/>
															Pedidos
													</Link>
											</li>
									</ul>
							</nav>
							<button onClick={()=>signOut()} >
							<IoLogOutOutline/>
							Sair
							</button>
					</SidebarContainer>
				<Background menuMobile={menuMobile} onClick={() => setMenuMobile(false)}/>
			</>
		)
}
