import HeaderLateral from "../../components/HeaderLateral";
import Produtosdash from "../../components/Dash/Produtosdash";
import './produtos.css';
import {Helmet} from 'react-helmet';

export default function Produtos(){
    return(
        <div className="produtoscontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Produtos</title>
            </Helmet>
            <HeaderLateral/>
            <Produtosdash/>
        </div>
    )
}