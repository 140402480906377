import { Link } from 'react-router-dom';
import {IoCart} from 'react-icons/io5';
import { LojasContainer } from './estilo';

export default function Lojas(props){

    async function selecionaLoja(loja){
            localStorage.setItem('dataLoja',JSON.stringify(loja));
    }

    return(
        <LojasContainer>
                {props.lj.map((loja)=>{
                    return(
                        <div key={loja.id} className='loja-item'>
                            <div className='loja-titulo'>
                                <IoCart size={50} color='#49A07F'/>
                                <h1>{`Identificação: ${loja.id}`}</h1>
                            </div>
                            <div className='loja-conteudo'>
                                <h1>{`Centro de Custo: ${loja.centroCusto}`}</h1>
                                <h1>{`Loja: ${loja.nome}`}</h1>
                                <h1>{`Varejo: ${loja.varejo}`}</h1>
                                <h1>{`Endereço: ${loja.endereco}`}</h1>
                            </div>
                            <div className='area-button-loja'>
                                <Link to='/dashboard' onClick={()=>selecionaLoja(loja)}>SELECIONAR</Link>
                            </div>
                            
                        </div>
                    )
                })}
            </LojasContainer>
    )
}