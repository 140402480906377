import * as yup from "yup";
import { createContext } from "react";
import {yupResolver} from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";

export const UploadCsvContext = createContext({});

export default function UploadCsvProvider({children}){
    yup.addMethod(yup.mixed, "csvFormat", function (errorMessage) {
        return this.test(`csv-format`, errorMessage, function (value) {
            if(value[0].type === "text/csv"){
                return true
            };
            
            const { path, createError } = this;
        
            return createError({ path, message: errorMessage })
        });
    });

    const schema = yup.object().shape({
        csvFile: yup.mixed().nullable().csvFormat("Formato de arquivo inválido.").required("Selecione um arquivo.")
    })

    const { register, handleSubmit, reset , formState: {errors} } = useForm({
        resolver: yupResolver(schema)
    })

    return(
        <UploadCsvContext.Provider value={{register,handleSubmit,reset,errors}}>
            {children}
        </UploadCsvContext.Provider>
    )
}