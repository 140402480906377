import { RelatorioLinkContainer } from './estilo';
import { BsDownload } from "react-icons/bs";
import * as XLSX from 'xlsx/xlsx.mjs';

export default function RelatorioLink({dados,fields,filename}){
    const agora = new Date();
    
    function formatDate(date, format) {
        const map = {
            mm: date.getMonth() + 1,
            dd: date.getDate(),
            aaaa: date.getFullYear()
        }
    
        return format.replace(/mm|dd|aaaa/gi, matched => map[matched])
    }


    function criaRelatorio(){
        let name = `${filename+"_"+formatDate(agora,"dd/mm/aaaa")}.xlsx`;
        const dadosFiltrados = dados.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        var ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,filename);
        XLSX.writeFile(wb,name);
    }
    
    return (
        <RelatorioLinkContainer>
            <button onClick={criaRelatorio} className='exportar-button'>Download</button>
            <button onClick={criaRelatorio} className='icon-button'>
                <BsDownload size={20} style={{margin:"auto"}}/>
            </button>
        </RelatorioLinkContainer>
    )
}