import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import {ContainerNull,Container,ContainerProdutos,Titulo,AreaHeader,AreaPesquisa,ButtonNovo,ContainerTabela,Tabela,Thead,TR,TH,Tbody,TD,SpanStatus,ButtonEditar} from './estilo';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../contexts/auth';
import api from '../../../services/api';
import AlteraProduto from '../../modals/Editar_produto';
import InputPesquisa from '../../InputPesquisa';
import {IoAlertCircleOutline} from 'react-icons/io5';
import InserirProduto from '../../modals/Novo_produto';
import RelatorioLink from '../../RelatorioLink';

export default function Produtosdash(){
    const [modal,setModal] = useState(false);
    const [modalNovo,setModalNovo] = useState(false);
    const [dadosProduto,setDadosProduto] = useState('');
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);
    const [produtos,setProdutos] = useState([]);
    const [listaDeProdutos, setListaDeProdutos] = useState([]);
    const produtosFields = [
        'id',
        'codigo',
        'produto',
        'precoSP_barbosa',
        'precoAC',
        'precoAL',
        'precoAP',
        'precoAM',
        'precoBA',
        'precoCE',
        'precoDF',
        'precoES',
        'precoGO',
        'precoMA',
        'precoMT',
        'precoMS',
        'precoMG',
        'precoPA',
        'precoPB',
        'precoPR',
        'precoPE',
        'precoPI',
        'precoRN',
        'precoRS',
        'precoRO',
        'precoRR',
        'precoSC',
        'precoSP',
        'precoSE',
        'precoTO',
        'precoRJ',
        'preco_sp_guanabara',
        'preco_sp_fisica',
        'preco_df_fisica',
        'preco_df2_fisica',
        'preco_go_fisica',
        'preco_rj_fisica',
        'preco_anapolis_fisica',
        'descricao',
        'descricao2',
        'peso',
        'status',
        'categoria',
        'segmentoLoja'
    ];
    const componentMounted = useRef(true); 

    //busca todos os produtos
    const buscaProdutos = useCallback(() => {
        setLoading(true)
        api.get('/produtos',
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setProdutos(resp.data.produtos)
            setListaDeProdutos(resp.data.produtos)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    },[user.token, signOut]);
    
    useEffect(()=>{
        if (componentMounted.current){ 
            buscaProdutos()
        }
        return () => {
            componentMounted.current = false;
        }
    },[buscaProdutos]) 

    function enviaDados(item){
        setModal(!modal);
        setDadosProduto(item)
    }

    function abreNovo(){
        setModalNovo(!modalNovo);
    }

    function filtroDeProdutos(lista, valor){
        return lista.filter((produto) => produto.produto.toUpperCase().includes(valor.toUpperCase()) || produto.codigo.toUpperCase().includes(valor) || produto.categoria.toUpperCase().includes(valor.toUpperCase()));
    }
    if(loading){
        return(
            <Container>
                <ContainerProdutos>
                    <Titulo>Carregando...</Titulo>
                </ContainerProdutos>
            </Container>
        )
    }
    return(
        <Container>
            <ContainerProdutos>
                <Titulo>LISTA DE PRODUTOS</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={produtos} setLista={setListaDeProdutos} funcaoDeFiltro={filtroDeProdutos}/>
                    </AreaPesquisa>
                    <ButtonNovo onClick={abreNovo}>
                        Novo
                    </ButtonNovo>
                    <RelatorioLink dados={listaDeProdutos} fields={produtosFields} filename={"produtos"}/>
                </AreaHeader>
                {listaDeProdutos.length === 0 ? (
                    <ContainerNull>
                        <IoAlertCircleOutline size={50} color='#FF0000'/>
                        <h1>Nenhum item encontrado...</h1>
                    </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela>
                                <Thead>
                                    <TR>
                                        <TH scope='col'>Id</TH>
                                        <TH scope='col'>Codigo</TH>
                                        <TH scope='col'>Produto</TH>
                                        <TH scope='col'>Categoria</TH>
                                        <TH scope='col'>Status</TH>
                                        <TH scope='col'>#</TH>
                                    </TR>
                                </Thead>
                                    <Tbody>
                                            {listaDeProdutos.map((produto)=>{
                                                    return(
                                                        
                                                            <TR key={produto.id}>
                                                                <TD data-label='id'>{produto.id}</TD>
                                                                <TD data-label='usuario'>{produto.codigo}</TD>
                                                                <TD data-label='produto'>{produto.produto}</TD>
                                                                <TD data-label='categoria'>{produto.categoria}</TD>
                                                                <TD data-label='status'>
                                                                    <SpanStatus style={{backgroundColor:produto.status === 'ATIVO' ? '#29C013' : '#FF0000' }}>{produto.status}</SpanStatus>
                                                                </TD>
                                                                <TD data-label='#'>
                                                                    <ButtonEditar onClick={()=>enviaDados(produto)}>
                                                                        EDITAR
                                                                    </ButtonEditar>
                                                                </TD>
                                                            </TR>
                                                    )
                                                }
                                            )
                                            }
                                    </Tbody>
                            </Tabela>
                        </ContainerTabela>
                    )
                }
                {
                    modal && (
                        <AlteraProduto
                        conteudo={dadosProduto}
                        fechar={enviaDados}
                        atualizaProdutos={buscaProdutos}
                        />
                    )
                }
                {
                    modalNovo && <InserirProduto
                    fechar={abreNovo}
                    atualizaProdutos={buscaProdutos}
                    />
                }
            </ContainerProdutos>
        </Container>
    )
}