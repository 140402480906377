import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify";
import { AuthContext } from "../../../contexts/auth";
import api from "../../../services/api";
import InputPesquisa from "../../InputPesquisa";
import {IoAlertCircleOutline} from 'react-icons/io5';
import PrecoExecutadoTr from "../../Tr/PrecoExecutadoTr";
import Tabela from "../../Tabela";
import { AreaHeader,AreaFiltros,TituloFiltros,SelectMes,ContainerNull, AreaPesquisa, Container, ContainerPedidosFornecedor, ContainerTabela, Titulo} from "./estilo";
import RelatorioLink from '../../RelatorioLink';

export default function RelatorioPrecosExecutados(){
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [listaDeItems, setListaDeItems] = useState([]);
    const itemsFields = ['pedidoId','statusPedido','contato','codigo','produto','preco_oficial','preco_executado','lojaId','centroCusto','loja','varejo','cidade','estado','cep','usuarioId','usuario','formato','dataCriacao'];
    const [lojas,setLojas] = useState([])
    const [produtos,setProdutos] = useState([])

    //VARIAVEIS DE FILTRO
    const [mesSelecionado,setMesSelecionado] = useState(0)
    const [produtoSelecionado,setProdutoSelecionado] = useState(0)
    const [LojaSelecionado,setLojaSelecionado] = useState(0)

    useEffect(()=>{
        setLoading(true)
        function buscaLojas(){
            api.get('/lojas',
            {
                headers:{
                    'Authorization':user.token
                }
            }
            )
            .then((resp)=>{
                setLojas(resp.data.lojas)
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }
        function buscaProdutos(){
            api.get('/produtos',
                {
                    headers:{
                        'Authorization':user.token
                    }
                }
            )
            .then((resp)=>{
                setProdutos(resp.data.produtos)
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }
        buscaLojas()
        buscaProdutos()
        setLoading(false)
    },[])

    function buscaItemsPedido(mesSelecionado,lojaSelecionado,produtoSelecionado){
        setLoading(true)
        api.get('/produtos_pedido',
            {
                params:{
                    mes:mesSelecionado,
                    loja:lojaSelecionado,
                    produto:produtoSelecionado
                },
                headers:{
                    'Authorization':user.token,
                },
            }
        ).then((response) => {
            setMesSelecionado(0)
            setLojaSelecionado(0)
            setProdutoSelecionado(0)
            setItems(response.data.relatorioPedidos)
            setListaDeItems(response.data.relatorioPedidos)
            setLoading(false)
        }).catch((error) => {
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    };

    function filtroDeItems(lista, valor){
        return lista.filter((item) => item.pedidoId == valor || item.produto.toUpperCase().includes(valor.toUpperCase()) || item.loja.toUpperCase().includes(valor.toUpperCase()));
    }
    if(loading){
        return(
            <Container>
                <ContainerPedidosFornecedor>
                    <Titulo>Carregando...</Titulo>
                </ContainerPedidosFornecedor>
            </Container>
        )
    }
    return (
        <Container>
            <ContainerPedidosFornecedor>
                <Titulo>PREÇOS EXECUTADOS</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={items} setLista={setListaDeItems} funcaoDeFiltro={filtroDeItems}/>
                    </AreaPesquisa>
                    <RelatorioLink dados={listaDeItems} fields={itemsFields} filename={"precos_executados"}/>
                </AreaHeader>
                
                <AreaFiltros>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <TituloFiltros>FILTROS</TituloFiltros>
                    </div>
                    <SelectMes onChange={(e)=> buscaItemsPedido(e.target.value,'','')}>
                        <option key={0} value={''} label={'Selecionar Mês'}/>
                        <option key={1} value={'01'} label={'JANEIRO'}/>
                        <option key={2} value={'02'} label={'FEVEREIRO'}/>
                        <option key={3} value={'03'} label={'MARÇO'}/>
                        <option key={4} value={'04'} label={'ABRIL'}/>
                        <option key={5} value={'05'} label={'MAIO'}/>
                        <option key={6} value={'06'} label={'JUNHO'}/>
                        <option key={7} value={'07'} label={'JULHO'}/>
                        <option key={8} value={'08'} label={'AGOSTO'}/>
                        <option key={9} value={'09'} label={'SETEMBRO'}/>
                        <option key={10} value={'10'} label={'OUTUBRO'}/>
                        <option key={11} value={'11'} label={'NOVEMBRO'}/>
                        <option key={12} value={'12'} label={'DEZEMBRO'}/>
                    </SelectMes>
                    <SelectMes onChange={(e)=> buscaItemsPedido('','',e.target.value)} >
                        <option key={0} value={''} label={'Selecionar produto'}/>
                        {produtos.map((produto) => 
                            {
                            return <option key={produto.id} value={produto.id} label={produto.descricao}/>
                            }
                        )}
                    </SelectMes>
                    <SelectMes onChange={(e)=> buscaItemsPedido('',e.target.value,'')} >
                        <option key={0} value={''} label={'Selecionar loja'}/>
                        {lojas.map((loja) => 
                            {
                            return <option key={loja.id} value={loja.id} label={loja.nome}/>
                            }
                        )}
                    </SelectMes>
                </AreaFiltros>
                {listaDeItems.length === 0 ? (
                    <ContainerNull>
                        <IoAlertCircleOutline size={50} color='#FF0000'/>
                        <h1>Nenhum item encontrado...</h1>
                    </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela titleColumns={['Pedido','Produto','Preco Oficial','Preco Executado','Loja','Data Criação']}>
                                {listaDeItems.map((item, index)=>{
                                    return <PrecoExecutadoTr key={index} items={item} buscaItemsPedido={buscaItemsPedido} Admin/>
                                })}
                            </Tabela>
                        </ContainerTabela>
                    )
                }
            </ContainerPedidosFornecedor>
        </Container>
    )
}