import {useContext, useEffect} from 'react';
import { Route, useHistory,useLocation,Redirect} from 'react-router-dom';


import { AuthContext } from '../contexts/auth'; 


export default function RouteWrapper({
    component: Component,
    isPrivate=[],
    ...rest
}){
    const {signed,loading,permissao} = useContext(AuthContext);
    const location = useLocation();
    const history = useHistory();
    const estaPermitido = isPrivate.some((valor) => permissao.includes(valor));

    useEffect(()=>{
        if(permissao.length){
            //Se for diferente de logado e a rota for privada redireciona para rota de signin
            if(!signed && Boolean(isPrivate.length) && location.pathname !== '/notfound'){
                return history.push("/");
            }
            // Se está logado mas não tem permissão redireciona para a rota principal
            else if(signed && !estaPermitido && (permissao.includes('USER') || permissao.includes('ADMIN'))){
                history.push('/home')
            }
            else if(signed && !estaPermitido && permissao.includes('FORN')){
                history.push("/fornecedor/pedidos")
            }
            // Se não estiver permitido e não estiver na rota de not found redireciona para rota de not found.
            else if(!estaPermitido && !location.pathname === '/notfound'){
                history.push("/notfound")
            }
        }
    },[signed,permissao.length])

    if(loading){
        return(
            <div>
                <h1>Carregando...</h1>
            </div>
        )
    }
    //Se não estiver logado e rota for privada
    else if(!signed && isPrivate.length){
        return <Redirect to="/"/>
    }

    return(
        <Route
            {...rest}
            render={props=>(
                <Component {...props}/>
            )}
        />
    )
}

