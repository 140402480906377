import AdicionarAcessoProvider from "./adicionarAcesso";
import AdicionarFornecedorProvider from "./adicionarFornecedor";
import AdicionarLojaProvider from "./adicionarLoja";
import AdicionarProdutoProvider from "./adicionarProduto";
import AdicionarUsuarioProvider from "./adicionarUsuario";
import AuthProvider from "./auth";
import SolicitarPedidosProvider from "./solicitarPedidos";
import UploadCsvProvider from "./uploadCsv";
import UploadXlsxProvider from "./uploadXlsx";

export default function Providers({children}){
	return (
		<UploadXlsxProvider key={0}>
			<UploadCsvProvider key={1}>
				<AdicionarFornecedorProvider key={2}>
					<AdicionarProdutoProvider key={3}>
						<AdicionarAcessoProvider key={4}>
							<AdicionarLojaProvider key={5}>
								<AdicionarUsuarioProvider key={6}>
									<SolicitarPedidosProvider key={7}>
										<AuthProvider key={8}>
											{children}
										</AuthProvider>
									</SolicitarPedidosProvider>
								</AdicionarUsuarioProvider>
							</AdicionarLojaProvider>
						</AdicionarAcessoProvider>
					</AdicionarProdutoProvider>
				</AdicionarFornecedorProvider>
			</UploadCsvProvider>
		</UploadXlsxProvider>
	);
}