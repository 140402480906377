import styled from 'styled-components';

export const TR = styled.tr`
    position: relative;
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 20px;
    text-align: center;

    > td {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
    }

    @media(max-width:700px){
        border-bottom: 3px solid #DDD;
        display: block;
        margin-bottom: .65em;

        > td{
            border-bottom: 1px solid #DDD;
            display: block;
            font-size: .8em;
            text-align: right;
            &::before{
                content: attr(data-Label);
                float: left;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
`;

export const SelectTd = styled.td`
    padding: 0;
`

export const StatusSelect = styled.select`
    padding: 5px;
    width: 100%;
	border-radius: 5px;
	overflow-y: auto;

    li {
        border-bottom: 1px solid #ccc;
    }

    li:first-child {
        padding-top: 0;
    }

    li:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }
`

export const StatusTd = styled.td`
    font-weight: 700;
    color: #fff;
    background-color: ${({status}) => {
        switch (status) {
            case 'NAO RECEBIDO':
                return '#fc030b';
            case 'CANCELADO':
                return '#fc030b';
            case 'PRODUCAO':
                return '#fc9d03';
            case 'RETIRADO':
                return '#02d402';
            case 'ENTREGUE':
                return '#02d402';
            case 'PRONTO':
                return '#02d402';
            case 'AGUARDANDO':
                return '#fce303';
            case 'RECEBIDO':
                return '#89a0ad';
            default:
                return '#000';
        }
    }};
`

export const InputObs = styled.input`
    padding: 3.6px;
    width: 100%;
	border-radius: 5px;
`

export const ButtonEditar = styled.button`
    border: 0;
    padding: 5px;
    align-items: center;
    background-color: red;
    color: #FFF;
    font-weight: bold;
    border-radius: 3px ;
    &:hover{
        background-color: #FFF;
        color: #00A8FF;
    }
`;

export const ButtonSalvar = styled(ButtonEditar)`
    background-color: rgb(41, 192, 19);
`

export const ButtonFechar = styled.button`
    border-style: none;
    padding: 5px;
    margin-left: 2px;
    width: 25px;
    color: #fff;
    border-radius: 3px;
    background-color: #ff0000;
    &:hover{
        background-color: #FFF;
        color: #00A8FF;
    }
`