import styled from "styled-components";

export const Modal = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background:rgba(0,0,0,0.5);
    z-index:99 ;
`;

export const ContainerModal = styled.div`
    position:fixed;
    border-radius:9px;
    max-width:55%;
    top:5%;
    left:0;
    right:0;
    bottom:5%;
    margin:0 auto;
    background-color:#f7f7f7;
    overflow-y:auto;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items:center;

    > h1 {
        color: #FFF;
        background-color:#F28F1A;
        border-top-left-radius:9px;
        border-top-right-radius:9px;
        padding:10px;
        text-align:center;
        width: 100%;
    }

    > .fornecedor-form {
        display:flex;
        flex-direction: column;
        justify-content: center;
        margin-top:5%;
        margin-bottom:5%;
        width:100%;
        max-width: 700px;
        padding:10px;
        box-shadow: 0 0 20px rgba(0,0,0 0.5);
    }

    > .fornecedor-form > .input-container-fornecedor{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px 0;
        gap: 20px;
        width: 100%;
    }

    > .fornecedor-form > .input-container-fornecedor > div  > h3 {
        font-size: 20px;
        margin-right:5px;
        margin-bottom:5px;
        font-weight:bold ;
    }

    > .fornecedor-form > .input-container-fornecedor > div > input {
        font-size: 20px;
        height:30px;
        border-radius:9px;
        margin-right:5px;
        padding-left:5px;
    }

    > .fornecedor-form > .select-container-fornecedor {
        display: flex;
        justify-content: space-evenly;
        margin: 10px 0;
        gap: 20px;
    }

    > .fornecedor-form > .select-container-fornecedor > div > select {
        font-size:15px;
        border-radius:9px;
        border-color:#F28F1A ;
        padding: 3px;
    }

    .button-container-fornecedor{
        display: flex;
        justify-content: center;
        column-gap: 20px;
    }
    
    .button-container-fornecedor > button {
        padding: 10px;
        width: 80px;
        height:35px;
        background-color: #00A8FF;
        border:0;
        border-radius:9px;
        color:#FFF;
        font-weight:bold ;
        margin-bottom:10px;
    }

    @media screen and (max-width: 700px){
        max-width: 90%;

        > .fornecedor-form > .select-container-fornecedor{
            flex-direction: column;
            margin-bottom: 20px;
            align-items: center;
        }
    }
`