import HeaderLateral from "../../components/HeaderLateral";
import Lojasdash from "../../components/Dash/Lojasdash";
import './pagelojas.css';
import {Helmet} from 'react-helmet';

export default function Lojas(){
    return(
        <div className="lojascontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Lojas</title>
            </Helmet>
            <HeaderLateral/>
            <Lojasdash/>
        </div>
    )
}