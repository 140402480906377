import './signin.css';
import img from '../../img/swift-laranja-preto.svg';
import {useState, useContext} from 'react';
import { AuthContext } from '../../contexts/auth';
import {Helmet} from 'react-helmet';

export default function SignIn() {

  const [login,setLogin] = useState('');
  const [pass,setPass] = useState('');

  const {signIn,loadingAuth} = useContext(AuthContext);

  function Acesso(e){
    e.preventDefault();
    if(login !== '' && pass !== ''){
      signIn(login,pass)
    }
    else{
      alert('Preecher campos')
    }
  }
    return (
      <div className='area-signin'>
        <Helmet>
          <meta charSet='utf-8'/>
          <title>Login</title>
        </Helmet>
        <div className='area-login'>
            <div className='area-logo'>
                <img src={img} alt='logo'/>
            </div>
            <form onSubmit={Acesso}>
              <input type="text" placeholder='Login' value={login} onChange={(e)=>setLogin(e.target.value)}/>
              <input type="password" placeholder="*******" value={pass} onChange={(e)=>setPass(e.target.value)}/>
              <button type='submit'>{loadingAuth ? 'Carregando...' : 'ENTRAR'}</button>
            </form>
        </div>
      </div>
    );
  }