import { useContext, useState } from "react";
import { AdicionarFornecedorContext } from "../../../contexts/adicionarFornecedor";
import { ContainerModal, Modal } from "./estilo";
import api from "../../../services/api";
import { AuthContext } from "../../../contexts/auth";
import { toast } from "react-toastify";

export default function ModalNovoFornecedor({fechar}){
    const [cnpj, setCnpj] = useState('');
    const [cep,setCep] = useState('');
    
    const {user,signOut} = useContext(AuthContext);
    const {cepMask,cnpjMask,reset,register,handleSubmit,errors} = useContext(AdicionarFornecedorContext);

    function criarFornecedor(data){
        api.post('fornecedores', data, {
            headers:{
                'Authorization':user.token
            }
        }).then((res) => {
            toast.success(res.data.mensagem);
            window.location.reload();
        }).catch((error) => {
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            } else {
                toast.error(error.response.data.error);
            }
        })
    }

    return (
        <Modal onClick={fechar}>
            <ContainerModal onClick={(e) => e.stopPropagation()}>
                <h1>INSERIR FORNECEDOR</h1>
                <form className="fornecedor-form" onSubmit={handleSubmit(criarFornecedor)}>
                    <div className="input-container-fornecedor">
                        <div>
                            <h3>Nome</h3>
                            <input {...register('nome')}/>
                            {errors.nome && <p style={{color:'red'}}>{errors.nome.message}</p>}
                        </div>
                        <div>
                            <h3>Cnpj</h3>
                            <input value={cnpj} {...register('cnpj',{onChange: (e) => setCnpj(cnpjMask(e.target.value))})}/>
                            {errors.cnpj && <p style={{color:'red'}}>{errors.cnpj.message}</p>}
                        </div>
                        <div>
                            <h3>Email</h3>
                            <input {...register('email')}/>
                            {errors.email && <p style={{color:'red'}}>{errors.email.message}</p>}
                        </div>
                        <div>
                            <h3>Cidade</h3>
                            <input {...register('cidade')}/>
                            {errors.cidade && <p style={{color:'red'}}>{errors.cidade.message}</p>}
                        </div>
                        <div>
                            <h3>Estado</h3>
                            <input {...register('estado')}/>
                            {errors.estado && <p style={{color:'red'}}>{errors.estado.message}</p>}
                        </div>
                        <div>
                            <h3>Endereço</h3>
                            <input {...register('endereco')}/>
                            {errors.endereco && <p style={{color:'red'}}>{errors.endereco.message}</p>}
                        </div>
                        <div>
                            <h3>Cep</h3>
                            <input value={cep} {...register('cep', {onChange: (e) => setCep(cepMask(e.target.value))})}/>
                            {errors.cep && <p style={{color:'red'}}>{errors.cep.message}</p>}
                        </div>
                    </div>
                    <div className="select-container-fornecedor">
                        <div>
                            <h3>Status</h3>
                            <select {...register('status')}>
                                <option value='ATIVO'>ATIVO</option>
                                <option value='DESATIVO'>DESATIVADO</option>
                            </select>
                            {errors.status && <p style={{color:'red'}}>{errors.status.message}</p>}
                        </div>
                        <div>
                            <h3>Tipo de Entrega</h3>
                            <select {...register('tipoEntrega')}>
                                <option value='ENTREGUE NA LOJA'>ENTREGUE NA LOJA</option>
                                <option value='PEDIDO A RETIRAR'>PEDIDO A RETIRAR</option>
                            </select>
                            {errors.tipoEntrega && <p style={{color:'red'}}>{errors.tipoEntrega.message}</p>}
                        </div>
                    </div>
                    <div className="button-container-fornecedor">
                        <button type='button' onClick={fechar}>Voltar</button>
                        <button type='submit'>Criar</button>
                    </div>
                </form>
            </ContainerModal>
        </Modal>
    )
}