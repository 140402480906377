
const PermissaoGlobal = ({children,permission,acceptedPermissions=[]}) =>{
    if(permission.some((item)=>{
        return acceptedPermissions.includes(item);
        })
    ){
        return children;
    }
    return null;
}

export default PermissaoGlobal;