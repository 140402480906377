import styled from 'styled-components';

export const UploadXlsxForm = styled.form`
    display: flex;
    align-items: flex-end;
    width: 220px;
    padding: 0;
    
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        padding: 10px 20px 10px 10px;
    }

    .container > h6{
        text-align: center;
        font-size: 18px;
        color: #F28F1A;
    }

    .input-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;;
        height: initial;
        gap: 10px;
    }

    .input-container > p{
        max-width: 110px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .input-container > label{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        padding: 5px;
        width: 25px;
        height: 25px;
        text-align: center;
        background-color: #DC4405;
        color: #FFF;
        cursor: pointer;
    }

    .input-container > input[type=file]{
        display:none;
    }

    .button-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
    }

    .button-container > button{
        width: 80px;
        padding: 5px;
        background-color: #00A8FF;
        border: 0;
        border-radius: 9px;
        color: #FFF;
        font-weight: bold;
    }

    .button-container > a {
        background-color: green;
        color: #fff;
        padding: 5px 10px;
        border-radius: 10px;
    }
`