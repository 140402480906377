import styled from "styled-components";

export const InputPesquisaContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 5px;
`

export const InputPesquisar = styled.input`
    border: 0;
    margin-left: 5px;
    font-size: 16px;

    @media screen and (max-width: 700px){
        width: 100%;
    }
`;