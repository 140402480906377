import './encerrarPedido.css';
import api from '../../services/api.js';
import { useState,useContext } from 'react';
import {AuthContext} from '../../contexts/auth.js';
import { toast } from 'react-toastify';
import getDataUTC from '../../utils/date/dataUTC';

export default function EncerrarPedido({pedido,close}){
    const [cpf,setCpf] = useState('');
    const [status,setStatus] = useState('');
    const [obs,setObs] = useState('');

    //Busca propriedades do contexts
    const {user,signOut} = useContext(AuthContext);

    //ENCERRA PEDIDO
    async function encerrarPedido(){
        const data = getDataUTC(3);
        if(status !== '' && cpf !== ''){
            await api.patch('/pedidos',
                {
                    pedido_id:pedido.id,
                    documento:cpf,
                    statusPedido:status,
                    dataEntrega:data.slice(0,10),
                    observacao:obs
                },
                {
                        headers:{'Authorization':user.token}
                }
            ).then((resp)=>{
                toast.success(resp.data.mensagem)
                setCpf('');
                setStatus('');
                setObs('');
                window.location.reload();
            }).catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                }
            })
        }else{
            toast.error('Verifique os campos preenchidos')
        }
        
    }
    return(
        <div className='modalEncerrar'>
            <div className='modalEncerrar-container'>
                <h1>Encerrar Pedido: {pedido.id}</h1>
                <div className='modalEncerrar-componentes'>
                    <span>IDENTIFICAÇÂO DO RESPONSAVEL</span>
                    <input type='text' placeholder='INSERIR CPF' value={cpf} onChange={(e)=>setCpf(e.target.value)}/>
                    <span>STATUS DO PEDIDO</span>
                    <select onChange={(e)=>setStatus(e.target.value)}>
                        <option value=''>SELECIONAR</option>
                        <option value='ENTREGUE'>ENTREGUE</option>
                        <option value='RETIRADO'>RETIRADO</option>
                    </select>
                    <span>INFORMAÇÕES</span>
                    <input placeholder='OBSERVAÇÕES' value={obs} onChange={(e)=>setObs(e.target.value)}/>
                        <div className='modalEncerrar-botao'>
                            <button onClick={encerrarPedido}>SALVAR</button>
                            <button onClick={close}>VOLTAR</button>
                        </div>
                </div>
                
            </div>
        </div>
    )
}