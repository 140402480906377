import { StyledTable, StyledTBody } from "./estilo"

export default function ErrosTable({titleColumns,children}){
    return (
        <StyledTable>
            <thead>
                <tr>
                    {titleColumns.map((title,index) => {
                        return <th scope='col' key={index}>{title}</th>
                    })}
                </tr>
            </thead>
            <StyledTBody>
                {children}
            </StyledTBody>
        </StyledTable>
    )
}