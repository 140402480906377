import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #EFEFEC;
`;

export const ContainerPedidosFornecedor = styled.div`
    margin-left: 230px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 9px;
    background-color: #FFF;
    @media(max-width:700px){
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 100%;
        border-radius: 9px;
        background-color: #FFF;
    }
`;

export const Titulo = styled.h1`
    background-color: #DC4405;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    color: #FFF;
    padding: 10px;
`;

export const AreaHeader = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ContainerNull = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    height: 250px;
    border: 1px solid black;
    background-color: #FFF;
    margin-bottom: 10px;
    padding: 8px;
`;

export const AreaPesquisa = styled.div`
    display: flex;
    width: 250px;
    margin: 15px;
    padding: 2px;
    border-radius: 9px;
    border:solid #EFEFEC 2px;
    background-color: #FFF;
`;

export const AreaFiltros = styled.div`
    display: flex;
    align-items:center;
    width: 100%;
    margin: 15px;
    padding: 15px;
`;

export const TituloFiltros = styled.text`
    font-size:25px;
`;

export const FiltroData = styled.input`
    height:50px;
    width:13%;
    min-width: 100px;
    text-align:center;
    border-radius:15px;
    margin-left:15px;
`

export const SelectPedidos = styled.select`
    height:50px;
    width:13%;
    text-align:center;
    border-radius:15px;
    margin-left:15px;
`;

export const ContainerTabela = styled.div`
    margin: 15px;
    display: flex;
    justify-content: center;
`;

export const TR = styled.tr`
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 20px;
    text-align: center;

    > th{
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
        background-color: #DC4405;
        color: #FFF;
        padding:10px;
        text-align: center;
    }
    @media(max-width:700px){
        border-bottom: 3px solid #DDD;
        display: block;
        margin-bottom: .65em;

        > td{
            border-bottom: 1px solid #DDD;
            display: block;
            font-size: .8em;
            text-align: right;
            &::before{
                content: attr(data-Label);
                float: left;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
`;

export const SpanStatus = styled.span`
    padding: 3px;
    border-radius: 3px;
    color: #FFF;
`;


