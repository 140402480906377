import './pedidosdash.css';
import EncerrarPedido from '../../EncerrarPedido';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Link} from 'react-router-dom';
import api from '../../../services/api';
import {toast} from 'react-toastify';
import { useState,useEffect,useContext, useRef, useCallback } from 'react';
import {IoAdd,IoCheckmarkCircleSharp,IoCloseCircleSharp} from 'react-icons/io5';
import {AuthContext} from '../../../contexts/auth.js';
import getDataUTC from '../../../utils/date/dataUTC';

export default function Pedidosdash(){
    const [abriModal,setAbriModal] = useState(false);
    const [pedido,setPedido] = useState('');
    const [pedidos,setPedidos] = useState([])
    const {user,signOut} = useContext(AuthContext);
    const [load,setLoad] = useState(false);
    const componentMounted = useRef(true); 
    //BUSCA TODOS OS PEDIDOS DA LOJA
    const buscaPedidos = useCallback(() => {
        setLoad(true)
        let dataLoja = localStorage.getItem('dataLoja')
        const loja = JSON.parse(dataLoja);
        api.get('/pedidos/usuario_loja/pedido',
            {
                headers:{'Authorization':user.token},
                params:{
                    usuario_id:user.id_usuario,
                    loja_id:loja.id
                }
            }
        ).then((resp)=>{
            setPedidos(resp.data.pedidos)
            toast.success(resp.data.mensagem)
            setLoad(false)
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.info(error.response.data.error)
                setLoad(false)
            }
        })
    },[user.token, user.id_usuario,signOut]);

    useEffect(()=>{
        if (componentMounted.current){ 
            buscaPedidos()
            setLoad(false);
        }
        return () => {
            componentMounted.current = false;
        }
    },[buscaPedidos])

    //ABRE MODAL ENCERRA PEDIDO
    function modalEncerrar(item){
        if(item.statusFornecedor !== 'NAO RECEBIDO'){
            setAbriModal(!abriModal)
            setPedido(item)
        }else{
            toast.error('Pedido deve ser cancelado')
        }
            
    }

    //BOX DIALOG
    const alertCancelar = (pedido) =>{
        confirmAlert({
            customUI:({onClose})=>{
                return(
                    <div className='box-dialog'>
                        <h1>CANCELAR PEDIDO</h1>
                        <p>Tem certeza que deseja cancelar?</p>
                        <button onClick={()=> {cancelarPedido(pedido); onClose()}}>SIM</button>
                        <button onClick={onClose}>NÃO</button>
                    </div>
                )
            }
        })
    }

    //CANCELAR PEDIDO
    function cancelarPedido(pedido){
            const data = getDataUTC(3);
            var dt = data.substring(0,10);
            var horaAtual = data.substring(10,13);
            var hm = pedido.horaEmissao.substring(0,2);
            var hora = horaAtual - hm
            var mt = pedido.horaEmissao.substring(3);
            var minAtual = data.substring(14);
            var minutos = minAtual - mt
            var sobra = (60 - mt) + parseInt(minAtual);
            
            setLoad(true)
            if(pedido.dataEmissao === dt && hora === 0 &&  minutos >= 0 && minutos <= 15 && pedido.paginas > 0 && pedido.statusFornecedor !== 'EM PROCESSO' && pedido.statusFornecedor !== 'PRONTO'){
                const dataLoja = localStorage.getItem('dataLoja')
                const jLoja = JSON.parse(dataLoja)
                //cancela e envia o email para o fornecedor
                    api.patch('/pedidos/cancelar_pedido',
                        {
                                pedido_id:pedido.id,
                                fornecedor:jLoja.email,
                                loja:jLoja.nome,
                                responsavel:jLoja.liderDistrital,
                                telefone:jLoja.telefoneD,
                                endereco:jLoja.endereco,
                                bairro:jLoja.bairro,
                                cep:jLoja.cep,
                                cidade:jLoja.cidade,
                                estado:jLoja.estado
                        },
                        {
                                headers:{'Authorization':user.token}  
                        }
                ).then((resp)=>{
                    toast.success(resp.data.mensagem)
                    setLoad(false)
                    window.location.reload();
                }).catch((error)=>{
                    if(error.response.status === 401){
                        toast.info(error.response.data.error)
                        signOut()
                    }else{
                        toast.error(error.response.data.error)
                        }
                    setLoad(false)
                    toast.error(error.response.data.error)
                })
            }
            else if(hora === 0 && minutos < 0 && minutos > 15 && pedido.paginas > 0 && pedido.statusFornecedor !== 'EM PROCESSO' && pedido.statusFornecedor !== 'PRONTO'){
                setLoad(false)
                toast.info('Ultrapassou o tempo maximo de cancelamento')
            }
            else if(pedido.dataEmissao === dt && hora === 1 &&  sobra >= 0 && sobra <= 15 && pedido.paginas > 0 && pedido.statusFornecedor !== 'EM PROCESSO' && pedido.statusFornecedor !== 'PRONTO'){
                const dataLoja = localStorage.getItem('dataLoja')
                const jLoja = JSON.parse(dataLoja)
                //Cancela e envia o email para o fornecedor
                api.patch('/pedidos/cancelar_pedido',
                        {
                                pedido_id:pedido.id,
                                fornecedor:jLoja.email,
                                loja:jLoja.nome,
                                responsavel:jLoja.liderDistrital,
                                telefone:jLoja.telefoneD,
                                endereco:jLoja.endereco,
                                bairro:jLoja.bairro,
                                cep:jLoja.cep,
                                cidade:jLoja.cidade,
                                estado:jLoja.estado
                        },
                        {
                                headers:{'Authorization':user.token}  
                        }
                ).then((resp)=>{
                    toast.success(resp.data.mensagem)
                    window.location.reload();
                    setLoad(false)
                }).catch((error)=>{
                    if(error.response.status === 401){
                        toast.info(error.response.data.error)
                        signOut()
                    }else{
                        toast.error(error.response.data.error)
                    }
                    setLoad(false)
                    toast.error(error.response.data.error)
                })
            }
            else if((pedido.paginas > 0 && pedido.statusFornecedor === 'EM PROCESSO') || pedido.statusFornecedor === 'PRONTO'){
                setLoad(false)
                toast.info('Pedido não pode ser cancelado.')
            }
            else if(pedido.paginas === 0 || pedido.statusPedido === 'NAO ENVIADO'){
                //CANCELA PEDIDO SEM ENVIAR EMAIL
                setLoad(true)
                api.patch('/pedidos',
                    {
                        pedido_id:pedido.id,
                        statusPedido:'CANCELADO'
                    },
                    {
                        headers:{'Authorization':user.token}
                    }
                ).then((resp)=>{
                    toast.success(resp.data.mensagem)
                    setLoad(false)
                    window.location.reload();
                }).catch((error)=>{
                    if(error.response.status === 401){
                        toast.info(error.response.data.error)
                        signOut()
                    }else{
                        toast.error(error.response.data.error)
                    }
                    setLoad(false)
                    toast.error(error.response.data.error)
                })                            
                }else{
                    setLoad(false)
                    toast.info('Ultrapassou o tempo maximo de cancelamento.')
                }
    }
    if(load === true){
        return(
            <div className='pedidosdash-container'>
                <div className='pedidosdash-container-pedidos'>
                    <h1>Carregando...</h1>
                </div>
            </div>
        )
    }
    return(
        <div className='pedidosdash-container'>
            <div className='pedidosdash-container-pedidos'>
            <h1>LISTA DE PEDIDOS</h1>
                {pedidos.length === 0 ? (
                    <div className='container-vazio'>
                        <h1>Nenhum pedido em andamento...</h1>
                        <Link to='/solicitarpedidos'>
                            <IoAdd size={30}/>
                            Solicitar Pedido
                        </Link>
                    </div>   
                   ) :
                    (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th scope='col'>Id</th>
                                        <th scope='col'>Fornecedor</th>
                                        <th scope='col'>Status</th>
                                        <th scope='col'>Endereço</th>
                                        <th scope='col'>Condição do pedido</th>
                                        <th scope='col'>N° Itens</th>
                                        <th scope='col'>emissão</th>
                                        <th scope='col'>entrega</th>
                                        <th scope='col'>Obs</th>
                                        <th scope='col'>#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        pedidos.map((pedido)=>{
                                            return(
                                                <tr key={pedido.id}>
                                                    <td data-label='Identificacao'>{pedido.id}</td>
                                                    <td data-label='Fornecedor'>{pedido.fornecedor}</td>
                                                    <td data-label='Status'>
                                                        <span className='status-fornecedor' style={{backgroundColor:pedido.statusFornecedor === 'NAO RECEBIDO' ? '#FF4933' : '#F9FF33' && pedido.statusFornecedor === 'PRONTO' ? '#5cb85c' : '#F9FF33'}}>{pedido.statusFornecedor}</span>
                                                    </td>
                                                    <td data-label='Endereco'>{pedido.endereco}</td>
                                                    <td data-label='Condicao Pedido'>{pedido.tipoEntrega}</td>
                                                    <td data-label='N° Paginas'>{pedido.paginas}</td>
                                                    <td data-label='Data Emissao'>{pedido.dataEmissao} {pedido.horaEmissao}</td>
                                                    <td data-label='Data Entrega'>{pedido.dataEntrega}</td>
                                                    <td data-label='Observacao'>{pedido.observacao}</td>
                                                    <td data-label='#'>
                                                            <button className='action' style={{backgroundColor:'#00FF00'}} onClick={()=> modalEncerrar(pedido)}>
                                                                <IoCheckmarkCircleSharp size={18}/>
                                                            </button>
                                                            <button className='action' style={{backgroundColor:'#FF0000'}} onClick={()=>alertCancelar(pedido)}>
                                                                <IoCloseCircleSharp size={18}/>
                                                            </button>
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                        </>
                    )
                    
                }
            </div>

            {abriModal && (
                <EncerrarPedido
                close={modalEncerrar}
                pedido={pedido}
                />
            )}

        </div>
    )
}