import HeaderLateral from "../../components/HeaderLateral";
import SelecionarPrecificador from "../../components/SelecionarPrecificador";
import './solicitarpedidos.css';
import api from '../../services/api';
import {IoClipboardOutline} from 'react-icons/io5';
import { useState ,useContext} from "react";
import { AuthContext } from "../../contexts/auth";
import { toast } from "react-toastify";
import {Helmet} from 'react-helmet';
import { SolicitarPedidosContext } from "../../contexts/solicitarPedidos";
import A3Horizontal from '../../files/A3-HORIZONTAL.pdf';
import A3Vertical from '../../files/A3-VERTICAL.pdf';

export default function SolicitarPedidos(){
    const {user,signOut} = useContext(AuthContext);
    const [cpf,setCpf] = useState('');
    const [obs,setObs] = useState('');
    const [pedidoId,setPedidoId] = useState('');
    const [formatoSelecionado,setFormatoSelecionado] = useState(0);
    const [listaProd] = useState([
        {id:1,nome:'precificador',valor:'precificador'},
    ])
    const {cpfMask,register,handleSubmit,errors} = useContext(SolicitarPedidosContext);
    //Controlar Modal
    const [abriModal,setAbriModal] = useState(false);
    const dataLoja = localStorage.getItem('dataLoja')
    const converterLoja = JSON.parse(dataLoja)
     

    function adicionarItens(){
        if(pedidoId !== ''){
            setAbriModal(!abriModal)
        }else{
            toast.info('Nenhum pedido em andamento')
        }
    }

    //CRIA PEDIDO
    function criarPedido(data, e){
        let lj = JSON.parse(dataLoja)

        const dadosProduto = {
            produto:data.produto,
            cpf:data.cpf,
            dataEntrega:"",
            tipoEntrega:lj.tipoEntrega,
            observacao:data.observacao,
            loja_id:lj.id,
            fornecedor_id:lj.fornecedorId,
            formato_id:data.formatoId,
            usuario_id:user.id_usuario
        }
        api.post('/pedidos/pedidosweb',
            dadosProduto,
            {
                headers:{'Authorization':user.token}
            }
        ).then((resp)=>{
            setPedidoId(resp.data.pedidoId)
            setAbriModal(!abriModal)
            toast.success('Adicione os itens ao pedido.')
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
            }
        })
    }
    
    return(
        <div className="solicitarcontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Solicitar Pedido</title>
            </Helmet>
            <HeaderLateral/>
            <div className="solicitar-interno">
                <div className="solicitar-areacomponentes">
                    <div className="solicitar-header">
                        <IoClipboardOutline size={34}/>
                        <h3>Solicitar Pedido</h3>
                    </div>
                    <div className="pedido-cadastro">
                        <div className="form-container">
                            <form className="solicitar-pedidos-form" onSubmit={handleSubmit(criarPedido)}>
                                <div className="input-container">
                                    <h3>Produto*</h3>
                                    <select id='selProd' {...register("produto")}>
                                            <option value='' >Selecionar</option>
                                            {listaProd.map((produto)=>(
                                                <option key={produto.id} value={produto.valor}>{produto.nome}</option>
                                                ))}
                                    </select>
                                    {errors.produto && <p style={{ color:"red"}}>{errors.produto.message}</p>}
                                </div>
                                <div className="input-container">
                                    <h3>Identificação*</h3>
                                    <input  type="text" placeholder='Inserir cpf' value={cpf} {...register("cpf", {onChange: (e) => setCpf(cpfMask((e.target.value)))})}/>
                                    {errors.cpf && <p style={{ color:"red"}}>{errors.cpf?.message}</p>}
                                </div>
                                <div className="input-container">
                                    <h3>Formato*</h3>
                                    <select id='selForm' {...register("formatoId",{onChange:(e)=>setFormatoSelecionado(e.target.value)})}>
                                            <option key={0} value={0}>Selecionar</option>
                                            <option key={1} value={1}>{'A4'}</option>
                                            {converterLoja.varejo !== 'Fisica' && <option key={2} value={2}>{'A3 Pedestal'}</option>}
                                            {<option key={3} value={3}>{'A3 Cavalete'}</option>}
                                            {(converterLoja.varejo === 'Fisica' || ["BARBOSA","LOPES"].includes(converterLoja.varejo.toUpperCase()))  && <option key={4} value={4}>{'Oportunidade A4'}</option>}
                                            {(converterLoja.varejo === 'Fisica' || ["BARBOSA","LOPES"].includes(converterLoja.varejo.toUpperCase())) && <option key={5} value={5}>{'Oportunidade Etiquetas'}</option>}
                                    </select>
                                    {errors.formatoId && <p style={{ color:"red"}}>{errors.formatoId.message}</p>}
                                    {formatoSelecionado == 2 && <a href = {A3Vertical} target = "_blank">Baixar modelo</a>}
                                    {formatoSelecionado == 3 && <a href = {A3Horizontal} target = "_blank">Baixar modelo</a>}
                                </div>
                                <div className="input-container">
                                    <h3>Observação</h3>
                                    <input type="text" placeholder="Observação" value={obs} {...register("observacao", {onChange: (e)=> setObs(e.target.value)})}/>
                                    {errors.observacao && <p style={{ color:"red"}}>{errors.observacao.message}</p>}
                                </div>
                                <div className="button-container">
                                    <button className="actionSolicitar" type="submit">
                                        SOLICITAR
                                    </button>
                                    <button type="button" className="actionSolicitar" onClick={adicionarItens}>
                                        (+) ITENS
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {abriModal && (
                <SelecionarPrecificador
                id={pedidoId}
                formato={formatoSelecionado}
                fechar={()=>setAbriModal(!abriModal)}
                />
            )}
        </div>
    )
}