import styled from "styled-components";

export const Modal = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background:rgba(0,0,0,0.5);
    z-index:99 ;
`;

export const ContainerModal = styled.div`
    position:fixed;
    border-radius:9px;
    max-width:50%;
    top:5%;
    left:0;
    right:0;
    bottom:5%;
    margin:0 auto;
    background-color:#f7f7f7;
    overflow-y:auto;

    .upload-container{
        display: flex;
        justify-content: flex-end;
    }

    .errors-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        top: 25px;
        left: 40px;
        padding: 5px 10px;
        font-weight: bold;
        font-size: 12px;
        background-color: red;
        color: #fff;
        border: none;
        border-radius: 10px;
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.3);
    }
    .errors-button:hover{
        background-color: white;
        color: red;
    }

    .errors-title {
        text-align: center;
        margin: 20px;
        color: red;
    }

    .errors-table-container {
        overflow-y: auto;
        border: 1px solid #CCC;
        border-radius:9px;
        height: 80%;
        width: 90%;
        margin: auto;
    }
    @media(max-width:800px){
        max-width:100%;
        overflow-y:auto;
        top:0;
        bottom:0;
    }
`;

export const Titulo = styled.h1`
    color: #FFF;
    background-color:#F28F1A;
    border-top-left-radius:9px;
    border-top-right-radius:9px;
    padding:10px;
    text-align:center;
`;

export const ContainerConteudo = styled.form`
    display:flex;
    flex-direction:column;
    margin-top:5%;
    margin-bottom:5%;
    width:100%;
    height:100%;
    padding:10px;
    box-shadow: 0 0 20px rgba(0,0,0 0.5);
    @media(max-width:800px){
        margin-top:5px;
    }
`;

export const ContainerItem = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    margin-bottom:10px;
    @media(max-width:800px){
        flex-direction:column;
    }
`;
export const AreaItem = styled.div`
    display:flex;
    flex-direction:column;
    margin-left:5%;
    width:250px;
    @media(max-width:800px){
        flex-direction:row;
        margin-left:0px;
        justify-content:space-between;
        width:95%;
        margin-bottom:5px;
    }
`;

export const AreaButton = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    margin-top:20px;
    margin-bottom: 10px;
`;

export const SubTitulo = styled.span`
    font-size: 20px;
    margin-right:5px;
    margin-bottom:5px;
    font-weight:bold ;
`;

export const Select = styled.select`
    font-size:15px;
    border-radius:9px;
    border-color:#F28F1A ;
    padding: 3px;
    @media(max-width:1400px){
        width:80%;
        font-size:12px;
    }
`;

export const Input = styled.input`
    font-size: 20px;
    height:30px;
    border-radius:9px;
    margin-right:5px;
    padding-left:5px;
    @media(max-width:1400px){
        width:80%;
        font-size:15px;
    }
`;

export const Button = styled.button`
    margin-right:5px;
    margin-left:5% ;
    padding: 10px;
    width: 80px;
    height:35px;
    background-color: #00A8FF;
    border:0;
    border-radius:9px;
    color:#FFF;
    font-weight:bold ;
    margin-bottom:10px;
`;