import styled from "styled-components";

export const AvalicaoChamadoStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);

.div-branca {
    position: relative;
    background-color: white;
    width: max-content;
    padding: 50px 50px 30px ;
    border-radius: 50px;
    width: 550px;
  }

  h1{   
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #370ada;
  }

  h2{
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #0e0e0e;
  }

  .div-images{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px auto 30px;

    svg{
        margin: 20px 0px;
        cursor: pointer;    
    }

    svg:hover{
        transform: scale(1.3);
        transition: 0.5s;
    }

    .selecionada{
        svg{
            transform: scale(1.3);
            transition: 0.5s;
        }
    }
  }

  .img-nao-selecionada{
    filter: grayscale(100%);
  }

  h3{
    font-size: 16px;
    font-weight: 500;
    color: #0e0e0e;
  }

  h5{
    color: #0e0e0e;
  }

  span{
    font-size: 14px;
    font-weight: 500;
    margin-left: 4px;
    color: #9c9a9a;
    font-style: italic;
  }

  .flex{
    justify-content: center;
    align-items: center;
  }

  textarea{
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    color: #0e0e0e;
    background-color: #E6E6E6;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    max-height: 300px;
    font-family: 'Roboto', sans-serif;
  }

  button{
    border: none;
    width: 194px;
    color: white;
    background-color: rgb(220, 68, 5);
    height: 43px;
    padding: 14px 0px;
    border-radius: 6px;
    font-weight: 700;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0px;
    font-family: 'Roboto', sans-serif;
  }

  .h2-bold{
    font-weight: 600;
    margin-bottom: 20px;
    color: #0e0e0e;
  }

  .relative{
    position: relative;
  }

  .legenda{
    position: absolute;
    bottom: -10px;
    font-size: 12px;
    text-align: center;
    left: -5px;
    line-height: 16px;
  }

  .legenda-curta{
    position: absolute;
    bottom: -4px;
    font-size: 12px;
    text-align: center;
    left: -6px;
  }

  .neutro{
    position: absolute;
    bottom: -4px;
    font-size: 12px;
    text-align: center;
    left: 8px;
  }

  .satisfeito{
    position: absolute;
    bottom: -4px;
    font-size: 12px;
    text-align: center;
    left: 0px;
  }

  .muito-satisfeito{
    position: absolute;
    bottom: -10px;
    font-size: 12px;
    text-align: center;
    left: -1px;
    line-height: 16px;
  }

  .flex{
    display: flex;
  }

  .fechar{
    position: absolute;
    right: -15px;
    top: -15px;
    cursor: pointer;
    background-color: #FA5252;
    padding: 20px 20px 15px 20px;
    border-radius: 100%;
  }
`