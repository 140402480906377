import { AvalicaoChamadoStyle } from "./style"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import feliz from "../../../img/feliz.svg"
import choro from "../../../img/choro.svg"
import triste from "../../../img/triste.svg"
import normal from "../../../img/molequeneutro.svg"
import muitoFeliz from "../../../img/muitofeliz.svg"
import fechar from "../../../img/fechar.svg"
import { useForm } from 'react-hook-form'
import apiPesquisaSatisfacao from '../../../services/apiPesquisaAvaliacao'
import api from "../../../services/api"
import { AuthContext } from "../../../contexts/auth"
import { ReactSVG } from "react-svg"

export const AvaliacaoChamadoModal = ({setStateModal}) => {

    const { user } = useContext(AuthContext);
    const { register, getValues, formState: {}} = useForm()
    
    const [avaliacaoSelecionada, setAvaliacaoSelecionada] = useState("")
    const [loadingRequest, setLoadingRequest] = useState(false)

    const enviarAvaliacao = () => {
        setLoadingRequest(true)
        const avaliacao = avaliacaoSelecionada
        const sugestao = getValues("sugestao")

        if(sugestao.length > 255){
            return toast.error("Limite de 500 caracteres de sugestão ultrapassado")
        }

        const notas = {
            "Choro": 1,
            "Triste": 2,
            "Normal": 3,
            "Feliz": 4,
            "Muito Feliz": 5
        }

        const body = {
            nota:notas[avaliacao],
            sugestao: sugestao,
            projeto: "Swift",
            modulos: "Swift",
            user_id: user.id_usuario,
            email: user.email,
        }

        if(!avaliacao){
            return toast.error("Selecione a sua satisfação")
        }

        apiPesquisaSatisfacao.post(`/pesquisa-satisfacao/`, body).then((response) => {
            toast.success("Avaliação enviado com sucesso! Obrigada 😉", {autoClose: 1500})
            api.patch('/usuarios',
                {
                    usuario_id: user.id_usuario,
                    data_oferecimento_avaliacao: new Date(new Date().setDate(new Date().getDate() + 30)).toLocaleDateString()
                },
                {
                    headers:{'Authorization':user.token}
                }
            ).then((response) => {
                setTimeout(() => {
                    window.location.reload()
                    setStateModal(false)
                }, 2000)
            },
            {
                headers:{'Authorization':user.token}
            }
            ).catch((error) => {
                console.log(error)
            }
            )
        }   
        ).catch((error) => {
            console.log(error);
            setLoadingRequest(false)
            toast.error("Erro ao enviar avaliação")
        }
        );
    }

    return(
        <AvalicaoChamadoStyle>
            <div className="div-branca">
                <ReactSVG className="fechar" src={fechar} onClick={() => setStateModal(false)}/>
                        <h2>Qual seu nível de satisfação com o nosso sistema?</h2>
                        <div className="div-images">
                            <div className="relative">
                                <ReactSVG onClick={() => setAvaliacaoSelecionada("Choro")} src={choro} alt="Choro" className={`img ${avaliacaoSelecionada == "Choro" ? "selecionada" : avaliacaoSelecionada ? "img-nao-selecionada" : ""}`}/>
                                <h5 className="legenda">Muito insatisfeito</h5>
                            </div>
                            <div className="relative">
                                <ReactSVG onClick={() => setAvaliacaoSelecionada("Triste")} src={triste} alt="Triste" className={`img ${avaliacaoSelecionada == "Triste" ? "selecionada" : avaliacaoSelecionada ? "img-nao-selecionada" : ""}`}/>
                                <h5 className="legenda-curta">Insatisfeito</h5>
                            </div>
                            <div className="relative">
                                <ReactSVG onClick={() => setAvaliacaoSelecionada("Normal")} src={normal} alt="Normal" className={`img ${avaliacaoSelecionada == "Normal" ? "selecionada" : avaliacaoSelecionada ? "img-nao-selecionada" : ""}`}/>
                                <h5 className="neutro">Neutro</h5>
                            </div>
                            <div className="relative">
                                <ReactSVG onClick={() => setAvaliacaoSelecionada("Feliz")} src={feliz} alt="Feliz" className={`img ${avaliacaoSelecionada == "Feliz" ? "selecionada" : avaliacaoSelecionada ? "img-nao-selecionada" : ""}`} />
                                <h5 className="satisfeito">Satisfeito</h5>
                            </div>
                            <div className="relative">
                                <ReactSVG onClick={() => setAvaliacaoSelecionada("Muito Feliz")} src={muitoFeliz} alt="Muito Feliz" className={`img ${avaliacaoSelecionada == "Muito Feliz" ? "selecionada" : avaliacaoSelecionada ? "img-nao-selecionada" : ""}`}/>
                                <h5 className="muito-satisfeito">Muito Satisfeito</h5>
                            </div>
                        </div>
                        <div className="flex">
                            <h3>Que tal nos deixar uma sugestão?</h3>
                            <span> (opcional)</span>
                        </div>
                        <textarea {...register("sugestao")}/>
                        <button disabled={loadingRequest} onClick={() => enviarAvaliacao()}>Enviar avaliação</button>
            </div>
        </AvalicaoChamadoStyle>
    )
}