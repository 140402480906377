import HeaderLateral from "../../components/HeaderLateral";
import Pedidosdash from "../../components/Dash/Pedidosdash";
import './dashboard.css';
import {Helmet} from 'react-helmet';

export default function Dashboard(){
    return(
        <div className="dashcontainer">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Lista Pedidos</title>
            </Helmet>
            <HeaderLateral/>
            <Pedidosdash/>
        </div>
    )
}