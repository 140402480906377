import {useState, createContext, useEffect} from 'react';
import api from '../services/api';
import {toast} from 'react-toastify';
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext({});

export default function AuthProvider({children}){
    const [user,setUser] = useState({status:false});
    const [loadingAuth,setLoadingAuth] = useState(null);
    const [loading,setLoading] = useState(true);
    const [permissao,setPermissao] = useState([]);

    function buscaUser(){
        const storageUser = localStorage.getItem('userSistema');

        if(storageUser){
            let dataUser = JSON.parse(storageUser);
            let decode = jwt_decode(dataUser.token)
            setPermissao([decode.permissao])
            setUser(JSON.parse(storageUser));
            setLoading(false);
        }
        setLoading(false);
    }

    useEffect(()=>{    
        buscaUser()
    },[])

    //INSERE NO STORAGE AS INFORMAÇÕES DO USUARIO
    function storageUser(data){
        localStorage.setItem('userSistema',JSON.stringify(data))
    }

    //FAZ LOGIN
    async function signIn(user,pass){
        setLoadingAuth(true);
        await api.post('/login',
            {
                usuario:user,
                senha:pass
            },
            {
                headers:{
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                }
            }
        ).then((resp)=>{
                let decoder = jwt_decode(resp.data.token)
                setUser(resp.data)
                storageUser(resp.data)
                setPermissao([decoder.permissao])
                setLoadingAuth(false)
        })
        .catch((error)=>{
            toast.error(error.response.data.error)
            setLoadingAuth(false)
        })
    }

    //FAZ LOGOUT
    function signOut(){
        localStorage.clear();
        window.location.reload();
    }

    async function buscaUsuarios(){
        setLoadingAuth(true)
        await api.get('/usuarios',
            {
                headers:{
                    'Authorization':user.token,
                    'Content-Type':'application/json',
                    'Accept':'application/json'
                }
            }
        )
        .then((resp=>{
            setLoadingAuth(false)
            return resp.data;
            
        })
        .catch((error)=>{
            toast.error(error.response.data.error)
            setLoadingAuth(false)
        })
        )
    }

    return(
        <AuthContext.Provider value={{signed: user.status,user,loading,signOut,signIn,loadingAuth,buscaUsuarios,permissao,buscaUser}}>
            {children}
        </AuthContext.Provider>
    )
}
