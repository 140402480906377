import { useState,useContext,useEffect } from 'react';
import api from '../../../services/api';
import { AuthContext } from '../../../contexts/auth';
import { toast } from 'react-toastify';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,AreaItem,SubTitulo,Select,Button,AreaButton} from './estilo.js';
import { AdicionarAcessoContext } from '../../../contexts/adicionarAcesso';
import { IoChevronBackOutline } from "react-icons/io5";
import ErrosTable from '../../ErrorsTable';
import ErrorsTr from '../../ErrorsTr';
import criarAcessosXlsx from '../../../planilhasDeExemplo/criar_acessos.xlsx';
import UploadXlsx from "../../UploadXlsx";

export default function InserirAcessos({fechar,atualizaAcessos}){
    const {user,signOut} = useContext(AuthContext);
    const {register,handleSubmit,reset,errors} = useContext(AdicionarAcessoContext);
    const [usuarios,setUsuarios] = useState([])
    const [lojas, setLojas] = useState([])
    const [fornecedores, setFornecedores] = useState([])
    const [usuarioSelecionado,setUsuarioSelecionado] = useState('')
    const [lojaSelecionado,setLojaSelecionado] = useState('')
    const [fornecedorSelecionado,setFornecedorSelecionado] = useState('')
    const [loading,setLoading] = useState(false);
    const [errorsMsg,setErrorsMsg] = useState('');

    useEffect(()=>{
        //BUSCA Usuarios
        function buscaUsuarios(){
            setLoading(true)
            api.get('/usuarios',
            {
                headers:{
                    'Authorization':user.token
                }
            })
            .then((resp)=>{
                setUsuarios(resp.data.usuarios)
                setLoading(false)
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }
        //BUSCA Lojas
        function buscaLojas(){
            setLoading(true)
            api.get('/lojas',
            {
                headers:{
                    'Authorization':user.token
                }
            })
            .then((resp)=>{
                setLojas(resp.data.lojas)
                setLoading(false)
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }
        //BUSCA FONECEDORES
        function buscaFornecedores(){
            setLoading(true)
            api.get('/fornecedores',
            {
                headers:{
                    'Authorization':user.token
                }
            })
            .then((resp)=>{
                setFornecedores(resp.data.fornecedores)
                setLoading(false)
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }
        buscaUsuarios()
        buscaLojas()
        buscaFornecedores()
    },[user.token,signOut])

    function adicionaAcesso(data){
        setLoading(true)
        api.post('/acessos/cadastro',
            {
                usuarioId:data.usuarioId,
                lojaId:data.lojaId,
                fornecedorId:data.fornecedorId
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
        ).then((resp)=>{
            toast.success(resp.data.mensagem)
            window.location.reload()
            setLoading(false);
        }).catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }

    function criarAcessos(results){
        const acessos = results.data;
        if(user.token){
            api.post('/acessos/cadastros', {acessos}, {
                headers:{
                    'Authorization': user.token
                }
            }).then((resp) => {
                toast.success(resp.data.mensagem)
                window.location.reload()
            }).catch((error) => {
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                } else if (error.response.status === 406) {
                    toast.info(error.response.data.error)
                } else if (error.response.status === 413){
                    toast.info('Quantidade de dados muito grande.');
                } else {
                    toast.info("Erro ao criar acessos.");
                    setErrorsMsg(error.response.data.errors);
                }
            })
        }
    } 

    return(
        <Modal>
            {errorsMsg ? (
                <ContainerModal>
                    <h1 className='errors-title'>Lista de erros</h1>
                    <div className='errors-table-container'>
                        <ErrosTable titleColumns={['mensagem','item','valor']}>
                            {errorsMsg.map((erro) => {
                                return <ErrorsTr erro={erro}/>
                            })}
                        </ErrosTable>
                    </div>
                    <button className='errors-button' onClick={(e) => setErrorsMsg('')}>
                        <IoChevronBackOutline size={20}/>
                        VOLTAR
                    </button>
                </ContainerModal>
            ) : (
                <ContainerModal>
                    <Titulo>INSERIR ACESSO</Titulo>
                    <div className='upload-container'>
                        <UploadXlsx resultCallback={criarAcessos} planilhaDeExemplo={criarAcessosXlsx}/>
                    </div>
                    <ContainerConteudo onSubmit={handleSubmit(adicionaAcesso)}>
                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>USUARIOS</SubTitulo>
                                    <Select value={usuarioSelecionado || ''} {...register('usuarioId',{onChange:(e)=>setUsuarioSelecionado(e.target.value)})}>
                                        <option key={0} value={0}>SELECIONAR</option>
                                        {usuarios.map((usuario)=>{
                                            return <option key={usuario.id} value={usuario.id}>{usuario.id} - {usuario.nome}</option>
                                        })
                                        }
                                    </Select>
                                    {errors.usuarioId && <p style={{color:'red'}}>{errors.usuarioId.message}</p>}
                                </AreaItem>
                            </ContainerItem>
                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>LOJAS</SubTitulo>
                                    <Select value={lojaSelecionado || ''} {...register('lojaId',{onChange:(e)=>setLojaSelecionado(e.target.value)})}>
                                        <option key={0} value={0}>SELECIONAR</option>
                                        {lojas.map((loja)=>{
                                            return <option key={loja.id} value={loja.id}>{loja.id} - {loja.nome}</option>
                                        })
                                        }
                                    </Select>
                                    {errors.lojaId && <p style={{color:'red'}}>{errors.lojaId.message}</p>}
                                </AreaItem>
                            </ContainerItem>
                            <ContainerItem>
                                <AreaItem>
                                    <SubTitulo>FORNECEDORES</SubTitulo>
                                    <Select value={fornecedorSelecionado} {...register('fornecedorId',{onChange:(e)=>setFornecedorSelecionado(e.target.value)})}>
                                        <option key={0} value={0}>SELECIONAR</option>
                                        {
                                        fornecedores.map((fornecedor)=>{
                                            return <option key={fornecedor.id} value={fornecedor.id}>{fornecedor.id} - {fornecedor.nome}</option>    
                                            }
                                        )
                                        }
                                    </Select>
                                    {errors.fornecedorId && <p style={{color:'red'}}>{errors.fornecedorId.message}</p>}
                                </AreaItem>
                            </ContainerItem>
                        <AreaButton>
                                <Button onClick={fechar}>VOLTAR</Button>
                                <Button type="submit">ADICICIONAR</Button>
                        </AreaButton>
                    </ContainerConteudo>
                </ContainerModal>
            )}
        </Modal>
    )
}