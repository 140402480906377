import styled from 'styled-components';

export const RelatorioLinkContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;

    .icon-button{
        display: inline-flex;
        font-size: 3rem;  
        text-decoration: none;  
        color: #F28F1A;
        width: 45px;
        height: 45px;   
        transition: .5s linear;
        position: relative;
        z-index: 1;
        margin: 10px;
        border: none;
        background-color:#fff;
    }

    .icon-button::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 45px;
        height: 45px;
        background: #F28F1A;
        border-radius: 50%;
        z-index: -1;
        transform: scale(0);
        transition: 0.3s cubic-bezier(.95, .32, .37, 1.21);
    }

    .icon-button:hover,
    .exportar-button:hover + .icon-button{
        color: #fff;
    }

    .icon-button:hover::before,
    .exportar-button:hover + .icon-button::before{
        transform: scale(1);
        color: #F28F1A;
    }

    .exportar-button{
        border: none;
        background-color:#fff;
        display: inline-flex;
        align-items: center;
        font-size: 16px;  
        font-weight: bold;
        text-decoration: none;
        color:#F28F1A;
    }
    @media(max-width:700px){
        .exportar-button{
            display:none;
        }
    }
`