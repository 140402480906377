import { StyledTable, Tbody, Thead } from "./estilo"

export default function Tabela({titleColumns, children}){

    return (
        <StyledTable>
            <Thead>
                <tr>
                    {titleColumns.map((title,index) => {
                        return <th scope='col' key={index}>{title}</th>
                    })}
                </tr>
            </Thead>
            <Tbody>
                {children}
            </Tbody>
        </StyledTable>
    )
}