import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import { createContext } from 'react';
import {cnpj} from 'cpf-cnpj-validator';

export const AdicionarFornecedorContext = createContext({});

export default function AdicionarFornecedorProvider({children}){
    function cnpjMask(value){
        return value
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }

    function cepMask(value){
        return value
            .replace(/\D+/g, "")
            .replace(/(\d{5})(\d{3})/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1')
    };      
    
    yup.addMethod(yup.string, 'cnpjValidate', function (errorMessage) {
        return this.test(`cnpj-validate`, errorMessage, function (value) {
            if(!value){
                return true;
            }
            
            const cnpjNumber = value.replace('.', '').replace('.', '').replace('/', '').replace('-', '')
            if(cnpj.isValid(cnpjNumber)){
                return value
            }
    
            const { path, createError } = this;
        
            return createError({ path, message: errorMessage })
        });
    });
    
    yup.addMethod(yup.number, 'maxLength', function(methodValue, errorMessage) {
        return this.test(`max-length`, errorMessage, function (value) {
            if(!value){
                return true;
            }
    
            if (String(value).length <= methodValue){
                return value
            }
    
            const { path, createError } = this;
            
            return createError({ path, message: errorMessage })
        });
    });
    
    yup.addMethod(yup.string, 'maxLength', function(methodValue, errorMessage) {
        return this.test(`max-length`, errorMessage, function (value) {
            if(!value){
                return true;
            }
            
            if (String(value).length <= methodValue){
                return value
            }
    
            const { path, createError } = this;
            
            return createError({ path, message: errorMessage })
        });
    });

    yup.addMethod(yup.string, "cepValidate", function (errorMessage) {
        return this.test(`cep-validate`, errorMessage, function (value) {
            const cepNumber = value.replace('.','').replace('-','');
            var validacep = /^[0-9]{8}$/;
            if(validacep.test(cepNumber)){
                return value;
            }
            const { path, createError } = this;
        
            return createError({ path, message: errorMessage })
        });
    });

    const schema = yup.object().shape({
        nome: yup.string('Formato inválido. Campo deve ser texto.').maxLength(50, 'Máximo de caracteres excedido(50).').required('Campo obrigatório.'),
        cnpj: yup.string('Formato inválido. Campo deve ser texto.').cnpjValidate('cnpj inválido. (00.000.000/0000-00).').required('Campo obrigatório.'),
        email: yup.string("Formato inválido.").maxLength(255, 'Máximo de caracteres excedido(255).').email("Email inválido.").required('Campo obrigatório.'),
        cidade: yup.string('Formato inválido. Campo deve ser texto.').maxLength(30, 'Máximo de caracteres excedido(30).').required('Campo obrigatório.'),
        estado: yup.string('Formato inválido. Campo deve ser texto.').maxLength(6, 'Máximo de caracteres excedido(6).').required('Campo obrigatório.'),
        endereco: yup.string('Formato inválido. Campo deve ser texto.').maxLength(80, 'Máximo de caracteres excedido(80).').required('Campo obrigatório.'),
        cep: yup.string('Formato inválido. Campo deve ser texto.').cepValidate('Cep inválido').required('Campo obrigatório.'),
        status: yup.string('Formato inválido. Campo deve ser texto.').matches(/(ATIVO|DESATIVADO)/, 'Campo deve ser ATIVO ou DESATIVADO.').required('Campo obrigatório.'),
        tipoEntrega: yup.string('Formato inválido. Campo deve ser texto.').matches(/(ENTREGUE NA LOJA|PEDIDO A RETIRAR)/, 'Campo deve ser ENTREGUE NA LOJA ou PEDIDO A RETIRAR.').required('Campo obrigatório.'),
    });

    const { register, handleSubmit, reset , formState: {errors} } = useForm({
        resolver: yupResolver(schema)
    })

    return(
        <AdicionarFornecedorContext.Provider value={{cepMask,cnpjMask,register,handleSubmit,reset,errors}}>
            {children}
        </AdicionarFornecedorContext.Provider>
    )
}