import styled from 'styled-components';

export const StyledTable = styled.table`
    &caption{
        font-size: 1.5em;
        margin: .5em 0 .75em;
    }
`;

export const Thead = styled.thead`
    display: table;
    width: 100%;
    table-layout: fixed;

    > tr{
        display: table;
        width: 100%;
        table-layout: fixed;
        padding: 20px;
        text-align: center;
    }

    > tr > th{
        font-size: .85em;
        letter-spacing: .1em;
        text-transform: uppercase;
        background-color: #DC4405;
        color: #FFF;
        padding:10px;
        text-align: center;
    }
    @media(max-width:700px){
        border: none;
        clip:rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
`;

export const Tbody = styled.tbody`
    display: block;
    width: 100%;
`;