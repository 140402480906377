import { useState,useEffect,useContext } from 'react';
import {Modal,ContainerModal,Titulo,ContainerConteudo,ContainerItem,TituloArea,AreaItem,SubTitulo,Select,Input,Button,AreaButton} from './estilo.js';
import {AuthContext} from '../../../contexts/auth';
import { toast } from 'react-toastify';
import api from '../../../services/api.js';

export default function AlteraCategoria({conteudo,fechar,atualizaCategoria}){
    const [categoria,setCategoria] = useState(conteudo.categoria)
    const [status, setStatus] = useState('');
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);

    //EDITA CATEGORIA
    function editaCategoria(){
        setLoading(true)
        if(categoria !== '' && status !== ''){
             api.patch('/categorias',
            {
                nome:categoria,
                status:status,
                categoria_id:conteudo.id
            },
            {
                headers:{
                    'Authorization':user.token
                }
            }
            )
            .then((resp)=>{
                toast.success(resp.data.mensagem)
                fechar()
                atualizaCategoria()
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    toast.info(error.response.data.error)
                    signOut()
                }else{
                    toast.error(error.response.data.error)
                    setLoading(false)
                }
            })
        }else{
            toast.info('Existem campos vazio')
        }
    }

    return(
        <Modal>
            <ContainerModal>
                <Titulo>EDITAR CATEGORIA</Titulo>
                <ContainerConteudo>
                    <TituloArea>DADOS CATEGORIA</TituloArea>
                    <ContainerItem>
                        <AreaItem>
                            <SubTitulo>CATEGORIA</SubTitulo>
                            <Input id='categoria' type='text' value={categoria || ''} onChange={(e)=> setCategoria(e.target.value)}></Input>
                        </AreaItem>
                        <AreaItem>
                            <SubTitulo>STATUS</SubTitulo>
                            <Select value={status} onChange={(e)=>{setStatus(e.target.value)}}>
                                <option key={0} value=''>Selecione</option>
                                <option key={1} value='ATIVO'>ATIVO</option>
                                <option key={2} value='DESATIVADO'>DESATIVADO</option>
                            </Select>
                        </AreaItem>
                    </ContainerItem>
                    <AreaButton>
                            <Button onClick={fechar}>VOLTAR</Button>
                            <Button onClick={editaCategoria}>SALVAR</Button>
                    </AreaButton>
                </ContainerConteudo>
            </ContainerModal>
        </Modal>
    )
}