import styled from 'styled-components';

export const StyledTable = styled.table`
    overflow-x:visible;
    border: none;

    & > thead > tr {
        background-color: #DC4405;
        color: #fff;
    }
`

export const StyledTBody = styled.tbody`
`