import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import {ToastContainer} from 'react-toastify';
import Providers from './contexts';

export default function App() {
  return (
    <Providers>
      <BrowserRouter>
        <ToastContainer autoClose={3000} position='top-center'/>
        <Routes/>
      </BrowserRouter>
    </Providers>
  );
}

