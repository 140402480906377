import { useState,useContext,useEffect, useRef, useCallback } from 'react';
import {ContainerNull,Container,ContainerAcessos,Titulo,AreaHeader,AreaPesquisa,ButtonNovo,ContainerTabela,Tabela,Thead,TR,TH,Tbody,TD,ButtonExcluir} from './estilo';
import {AuthContext} from '../../../contexts/auth';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import InputPesquisa from '../../InputPesquisa';
import InserirAcessos from '../../modals/Novo_acesso';
import {IoAlertCircleOutline} from 'react-icons/io5';
import RelatorioLink from '../../RelatorioLink';

export default function Acessosdash(){
    const {user,signOut} = useContext(AuthContext);
    const [loading,setLoading] = useState(false);
    const [acessos,setAcessos] = useState([]);
    const [listaDeAcessos, setListaDeAcessos] = useState([]);
    const [modalNovo,setModalNovo] = useState(false);
    const acessosFields = ['id','usuario','perfil','loja','fornecedor','createdAt','updatedAt'];
    const componentMounted = useRef(true); 
    
    //busca todos os acessos
    const buscaAcessos = useCallback(() => {
        setLoading(true)
        api.get('/acessos',
            {
                headers:{
                    'Authorization':user.token
                }
            }
        )
        .then((resp)=>{
            setAcessos(resp.data.acessos)
            setListaDeAcessos(resp.data.acessos)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    },[user.token, signOut]);

    useEffect(()=>{
        if (componentMounted.current){ 
            buscaAcessos()
        }
        return () => {
            componentMounted.current = false;
        }
    },[buscaAcessos])

    function filtroDeAcessos(lista, valor){
        return lista.filter((acesso) => acesso.usuario.toUpperCase().includes(valor.toUpperCase()) || acesso.loja.toUpperCase().includes(valor.toUpperCase()) || String(acesso.usuarioId) === valor || String(acesso.lojaId) === valor)
    }

    function abreNovo(){
        setModalNovo(!modalNovo);
    }

    function excluirAcesso(id){
        setLoading(true)
        api.delete('/acessos/deletar',
        {
            params:{
                acesso_id:id
            },
            headers:{
                "Authorization":user.token
            }
        }
        )
        .then((resp)=>{
            buscaAcessos()
            toast.success(resp.data.message)
            setLoading(false)
        })
        .catch((error)=>{
            if(error.response.status === 401){
                toast.info(error.response.data.error)
                signOut()
            }
            else{
                toast.error(error.response.data.error)
                setLoading(false)
            }
        })
    }
    if(loading){
        return(
            <Container>
                <ContainerAcessos>
                    <Titulo>Carregando...</Titulo>
                </ContainerAcessos>
            </Container>
        )
    }
    return(
        <Container>
            <ContainerAcessos>
                <Titulo>LISTA DE ACESSO USUARIO x LOJA</Titulo>
                <AreaHeader>
                    <AreaPesquisa>
                        <InputPesquisa lista={acessos} setLista={setListaDeAcessos} funcaoDeFiltro={filtroDeAcessos}/>
                    </AreaPesquisa>
                    <ButtonNovo onClick={abreNovo}>
                        Novo
                    </ButtonNovo>
                    <RelatorioLink dados={listaDeAcessos} fields={acessosFields} filename={"acessos"}/>
                </AreaHeader>
                {listaDeAcessos.length === 0 ? (
                        <ContainerNull>
                            <IoAlertCircleOutline size={50} color='#FF0000'/>
                            <h1>Nenhum item encontrado...</h1>
                        </ContainerNull>
                    ) :
                    (
                        <ContainerTabela>
                            <Tabela>
                                <Thead>
                                    <TR>
                                        <TH scope='col'>Id</TH>
                                        <TH scope='col'>Usuario</TH>
                                        <TH scope='col'>Perfil</TH>
                                        <TH scope='col'>Loja</TH>
                                        <TH scope='col'>Fornecedor</TH>
                                        <TH scope='col'>Criação</TH>
                                        <TH scope='col'>#</TH>
                                    </TR>
                                </Thead>
                                    <Tbody>
                                            {listaDeAcessos.map((acesso)=>{
                                                    return(
                                                        
                                                            <TR key={acesso.acessoId}>
                                                                <TD data-label='id'>{acesso.id}</TD>
                                                                <TD data-label='nome'>{acesso.usuario}</TD>
                                                                <TD data-label='perfil'>{acesso.perfil}</TD>
                                                                <TD data-label='loja'>{acesso.loja}</TD>
                                                                <TD data-label='fornecedor'>{acesso.fornecedor === 'SEM FORNECEDOR' ? 'N/A' : acesso.fornecedor}</TD>
                                                                <TD data-label='data'>{acesso.createdAt}</TD>
                                                                <TD data-label='#'>
                                                                    <ButtonExcluir onClick={()=> excluirAcesso(acesso.acessoId)}>
                                                                        EXCLUIR
                                                                    </ButtonExcluir>
                                                                </TD>
                                                            </TR>
                                                    )
                                                }
                                            )
                                            }
                                    </Tbody>
                            </Tabela>
                        </ContainerTabela>
                    )
                }
                {
                modalNovo && 
                (
                    <InserirAcessos
                    fechar={abreNovo}
                    atualizaAcessos={buscaAcessos}
                    />
                )
                }
            </ContainerAcessos>
        </Container>
    )
}