import { Switch } from "react-router-dom";
import Route from './Route';

import SignIn from '../pages/SignIn';
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import SolicitarPedidos from "../pages/SolicitarPedidos";
import Usuarios from "../pages/Usuarios";
import Lojas from "../pages/Lojas";
import Produtos from '../pages/Produtos';
import Categorias from '../pages/Categorias';
import Fornecedores from '../pages/Fornecedores';
import Acessos from '../pages/Acessos';
import FornecedorPedidos from "../pages/FornecedorPedidos";
import AdmPedidos from "../pages/AdmPedidos";
import PrecosExecutados from "../pages/PrecosExecutados";

export default function Routes(){
    return(
        <Switch>
            <Route exact path="/" component={SignIn}/>

            <Route exact path="/home" component={Home} isPrivate={['USER','ADMIN']}/>
            <Route exact path="/dashboard" component={Dashboard} isPrivate={['USER','ADMIN']}/>
            <Route exact path='/solicitarpedidos' component={SolicitarPedidos} isPrivate={['USER','ADMIN']}/>
            <Route exact path='/admin/usuarios' component={Usuarios} isPrivate={['ADMIN']}/>
            <Route exact path='/admin/lojas' component={Lojas} isPrivate={['ADMIN']}/>
            <Route exact path='/admin/produtos' component={Produtos} isPrivate={['ADMIN']}/>
            <Route exact path='/admin/categorias' component={Categorias} isPrivate={['ADMIN']}/>
            <Route exact path='/admin/fornecedores' component={Fornecedores} isPrivate={['ADMIN']}/>
            <Route exact path='/admin/acessos' component={Acessos} isPrivate={['ADMIN']}/>
            <Route exact path='/admin/pedidos' component={AdmPedidos} isPrivate={['ADMIN']}/>
            <Route exact path='/admin/relatorios/precos_executados' component={PrecosExecutados} isPrivate={['ADMIN']}/>
            <Route exact path='/fornecedor/pedidos' component={FornecedorPedidos} isPrivate={['FORN']}/>
            <Route exact path='/notfound' component={() => <h1>Não Encontrada.</h1>}/>
            <Route path='*' component={() => <h1>Não Encontrada.</h1>}/>
        </Switch>
    )
}